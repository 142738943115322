import axios from "axios";

const defaultUrl = process.env.REACT_APP_SERVER_URL+"/api/v1"

const loginWithData = (req) => {
    return axios.post(defaultUrl+"/auth", JSON.stringify(req), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*"
        }})
}

const foldersByUserId = (req) => {
    return axios.post(defaultUrl+"/foldersByUserId", JSON.stringify(req), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }})
}

const projects = (req) => {
    return axios.post(defaultUrl+"/projects", JSON.stringify(req), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }})
}

const history = (req) => {
    return axios.post(defaultUrl+"/history", JSON.stringify(req), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }})
}

const changeFolderName = (req) => {
    return axios.post(defaultUrl+"/changeFolderName", JSON.stringify(req), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }})
}

const deleteFolder = (req) => {
    return axios.post(defaultUrl+"/deleteFolder", JSON.stringify(req), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }})
}

const makeNewFolder = (req) => {
    return axios.post(defaultUrl+"/makeNewFolder", JSON.stringify(req), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }})
}

const loadUserProfile = () => {
    return axios.get(`${defaultUrl}/users/profile`, {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }
    })
}

const loadProjectWithId = (req) => {
    return axios.post(`${defaultUrl}/projectByProjectId`, JSON.stringify(req), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }
    })
}

const changePassword = (req) => {
    return axios.post(`${defaultUrl}/changePassword`, JSON.stringify(req), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }
    })
}

const summaryData = (req) => {
    return axios.post(`${defaultUrl}/summaryData`, JSON.stringify(), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }
    })
}

const copyProject = (req) => {
    return axios.post(`${defaultUrl}/copyProject`, JSON.stringify(req), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }
    })
}

const deleteProject = (req) => {
    return axios.post(`${defaultUrl}/deleteProject`, JSON.stringify(req), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }
    })
}

const changeProjectName = (req) => {
    return axios.post(`${defaultUrl}/changeProjectName`, JSON.stringify(req), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }
    })
}

const uploadImage = (req) => {
    return axios.post(`${defaultUrl}/uploadImage`, req, {
        headers: {
            "Content-Type": 'multipart/form-data',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }
    })
}

const makeNewProject = (req) => {
    return axios.post(`${defaultUrl}/makeNewProject`, JSON.stringify(req), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }
    })
}


const tts = (req) => {
    return axios.post(`${defaultUrl}/tts`, JSON.stringify(req), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }
    })
}

const lipsync = (req) => {
    return axios.post(`${defaultUrl}/lipsync`, JSON.stringify(req), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }
    })
}

const saveProject = (req) => {
    return axios.post(`${defaultUrl}/saveProject`, JSON.stringify(req), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }
    })
}

const getUsersSmartWords = (req) => {
    return axios.get(`${defaultUrl}/getUsersSmartWords`, {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }
    })
}

const saveUsersSmartWords = (req) => {
    return axios.post(`${defaultUrl}/saveUsersSmartWords`, JSON.stringify(req), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }
    })
}

const getAllVisibleColorTheme = (req) => {
    return axios.post(`${defaultUrl}/getAllVisibleColorTheme`, JSON.stringify(), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }
    })
}

const saveSettingByUserId = (req) => {
    return axios.post(`${defaultUrl}/saveSettingByUserId`, req, {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }
    })
}

const makeNewProjectFromTemplate = (req) => {
    return axios.post(`${defaultUrl}/makeNewProjectFromTemplate`, JSON.stringify(req), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }
    })
}

const changeProjectsFolder = (req) => {
    return axios.post(`${defaultUrl}/changeProjectsFolder`, JSON.stringify(req), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }
    })
}

const deleteProjects = (req) => {
    return axios.post(`${defaultUrl}/deleteProjects`, JSON.stringify(req), {
        headers: {
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin" : "*",
            Authorization: `Bearer ${localStorage.accessToken}`
        }
    })
}

const api = {
    loginWithData,
    foldersByUserId,
    projects,
    history,
    changeFolderName,
    deleteFolder,
    makeNewFolder,
    loadUserProfile,
    loadProjectWithId,
    changePassword,
    summaryData,
    copyProject,
    deleteProject,
    changeProjectName,
    uploadImage,
    makeNewProject,
    tts,
    lipsync,
    saveProject,
    getUsersSmartWords,
    saveUsersSmartWords,
    getAllVisibleColorTheme,
    saveSettingByUserId,
    makeNewProjectFromTemplate,
    changeProjectsFolder,
    deleteProjects
}

export default api;