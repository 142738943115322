export const btn1 = {
    fontFamily: 'Pretendard',
    fontWeight: 600,
    fontSize : '12px',
    lineHeight : '150%',
}

export const btn2 = {
    fontFamily: 'Pretendard',
    fontWeight: 500,
    fontSize : '14px',
    lineHeight : '150%',
}

export const btn3 = {
    fontFamily: 'Pretendard',
    fontWeight: 500,
    fontSize : '12px',
    lineHeight : '150%',
}

export const btn4 = {
    fontFamily: 'Pretendard',
    fontWeight: 500,
    fontSize : '18px',
    lineHeight : '150%',
}

export const btn5 = {
    fontFamily: 'Pretendard',
    fontWeight: 500,
    fontSize : '10px',
    lineHeight : '150%',
}