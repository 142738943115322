import { combineReducers, configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import thunk from "redux-thunk";
import authSlice from "../slice/auth";
import projectBgInfo from "../slice/creation/background/projectBgInfo";
import projectContentInfo from "../slice/creation/contents/projectContentInfo";
import creationSlice from "../slice/creation/creaton";
import actionSlice from "../slice/creation/models/action";
import modelSlice from "../slice/creation/models/model";
import projectModelInfo from "../slice/creation/models/projectModelInfo";
import styleSlice from "../slice/creation/models/style";
import scriptSlice from "../slice/creation/script";
import slideSlice from "../slice/creation/slide";
import tabSlice from "../slice/creation/tab";
import pageSlice from "../slice/page";
import projectsSlice from "../slice/projects";


const creationReducer = combineReducers({
    root: creationSlice,
    tab: tabSlice,
    script: scriptSlice,
    slide: slideSlice,
    model: modelSlice,
    style: styleSlice,
    modelP : projectModelInfo,
    contentP : projectContentInfo,
    action: actionSlice,
    background : projectBgInfo
})

const store = configureStore({
    reducer: {
        auth: authSlice,
        page: pageSlice,
        creation : creationReducer,
        projects: projectsSlice
    },
    middleware: [thunk, logger]
})

export const RootState = store.getState;

export const CreationState = store.getState().creation;

export default store;