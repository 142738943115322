import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../lib/api";
import { CreationState } from "../../../store";
import { pageSlice } from "../../page";
import { reset } from "../creaton";

import model1 from "../../../assets/img/dummy/_preview_1_nurse.png"
import model2 from "../../../assets/img/dummy/_preview_2_industry.png"
import model3 from "../../../assets/img/dummy/_preview_3_man army.png"
import model4 from "../../../assets/img/dummy/_preview_4_woman army.png"
import model5 from "../../../assets/img/dummy/_preview_5_doctor1.png"
import model6 from "../../../assets/img/dummy/_preview_6_doctor2.png"
import model7 from "../../../assets/img/dummy/_preview_7_woman police.png"
import model8 from "../../../assets/img/dummy/_preview_8_man police.png"
import model9 from "../../../assets/img/dummy/_preview_9_firefighter.png"
import model10 from "../../../assets/img/dummy/_preview_10_grandmother.png"
import model11 from "../../../assets/img/dummy/_preview_11_farmer.png"


const name = 'model';

export const fetchModel = createAsyncThunk(
    `${name}/fetchModel`,
    async (req, thunkAPI) => {
        try{
            return (await api.loadUserProfile()).data;
        } catch (e) {
            return thunkAPI.rejectWithValue(await e.response);
        }
    }
)

const initialState = {
    models: [
        {
            imageSrc: '',
            name: '',
            id: -1,
            modelName: '',
            previewSrc: ''
        }
    ],
    defaultScene: {},
    original: []
}

const dummyModels = [
    {imageSrc:null,name:'간호사',id:1000,previewSrc:model1},
    {imageSrc:null,name:'남군',id:1002,previewSrc:model3},
    {imageSrc:null,name:'여군',id:1003,previewSrc:model4},
    {imageSrc:null,name:'AI 의사(조성수)',id:1004,previewSrc:model5},
    {imageSrc:null,name:'AI 의사(조준환)',id:1005,previewSrc:model6},
    {imageSrc:null,name:'여성경찰',id:1006,previewSrc:model7},
    {imageSrc:null,name:'남성경찰',id:1007,previewSrc:model8},
    {imageSrc:null,name:'소방안전',id:1008,previewSrc:model9},
    {imageSrc:null,name:'건설현장',id:1001,previewSrc:model2},
    {imageSrc:null,name:'시장 할머니',id:1009,previewSrc:model10},
    {imageSrc:null,name:'농부',id:1010,previewSrc:model11}
]


export const modelSlice = createSlice({
    name,
    initialState,
    reducers: {
        setOriginal: (state, action) => {
            console.log(action.payload);
        }
    },
    extraReducers: {
        [fetchModel.pending.type]: (state, action) => {},
        [fetchModel.fulfilled.type]: (state, action) => {
            let modelArray = [];
            let modelArrayTop = [];
            state.original = action.payload.user.ai_models
            state.original.map((item) => {
                if(item.id === 1 || item.id === 11 || item.id === 10 || item.id === 13){
                    modelArrayTop.push({
                        imageSrc: item.silhouette_img_path,
                        name: item.model_description,
                        id: item.id,
                        modelName:item.model_name,
                        previewSrc: item.preview_path
                    })
                }else {
                    modelArray.push({
                        imageSrc: item.silhouette_img_path,
                        name: item.model_description,
                        id: item.id,
                        modelName:item.model_name,
                        previewSrc: item.preview_path
                    })
                }
            })

            let sortArr = [1,13,11,10];
            let modelArrayTopNew = [];
            sortArr.map((ArrIdx) => {
                if(modelArrayTop.find((item) => item.id === ArrIdx)){
                    modelArrayTopNew.push(modelArrayTop.find((item) => item.id === ArrIdx))
                }
            })


            

            modelArray = modelArrayTopNew.concat(modelArray);

            if (localStorage.getItem('email') === 'cnai_admin@cnai.ai'){
                modelArray = modelArray.concat(dummyModels);
            }

            state.defaultScene = {
                ai_model_item:{
                    file_path: "ai_model_preview.path",
                    scale: 100,
                    x_shift: 80,// 보정 치
                    y_shift: 45,
                    z_index: 1,
                    action: {
                        action_name: state.original[0].action_name,
                        preview_path: state.original[0].preview_path,
                        silhouette_img_path: state.original[0].silhouette_img_path,
                        style: {
                            id: state.original[0].styles[0].id,
                            ai_model_id: state.original[0].id,
                            ai_model: {
                                model_name: state.original[0].model_name
                            }
                        }
                    },
                    action_id: state.original[0].styles[0].actions[0].id
                },
                id: 0,
                bg_resize_option: "fill",
                project_id: -1,
                background_path: (action.payload.user.setting && action.payload.user.setting.default_background_img_path && action.payload.user.setting.default_background_img_path!=='') ? action.payload.user.setting.default_background_img_path : "https://cnaihumanstudio.s3.ap-northeast-2.amazonaws.com/uploadImages/bg01_v2.png",
                tts_path: null,
                inference_path: null,
                script: null,
                subtitle_item:{
                    is_rendering: true,
                    style_id: "dark"
                },
                image_items: [],
                text_items: [],
                isNew: false,
                lang:'Korean'
            };
            
            state.models = modelArray
        },
        [fetchModel.rejected.type]: (state, action) => {
            console.log('오류남');
        },
        [reset]: (state, action) => {
            Object.assign(state, initialState);
        }
    }
})


export const modelList = (state) => state.creation.model.models;

export const original = (state) => state.creation.model.original;

export const defaultScene = (state) => state.creation.model.defaultScene;

export const {setOriginal} = pageSlice.actions;

export default modelSlice.reducer;