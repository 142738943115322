import { createSlice } from "@reduxjs/toolkit/dist";
import { changeActions, contentActions, fetchImage, fetchImageByPdf, fetchProject, reset, sceneActions, slideActions } from "../creaton";


const name = 'ContentP';

const initialState = {
    images: [],
    texts: [],
    original: []
}

const initialImage = {
    path: '',
    coordinates: {
        x: 0,
        y: 0
    },
    zIndex: -1,
    id: -1,
    focusId: -1,
}

export const fontList = [
    { code:"NotoSansKR-Regular.otf", name: "Noto Sans CJK", type: "kor", font: "NotoSansKR" },
    { code:"NanumBarunpenR.otf", name: "나눔바른펜", type: "kor", font: "NanumBarunpen" },
    { code:"GmarketSansMedium.otf", name: "지마켓 산스", type: "kor", font: "GmarketSans" },
    { code:"Binggrae.otf", name: "빙그레체", type: "kor", font: "Binggrae" },
    { code:"SCDream4.otf", name: "에스코어 드림", type: "kor", font: "SCDream" },
    { code:"AbrilFatface-Regular.ttf", name: "AbrilFatface-Regular", type: "eng", font: "AbrilFatface" },
    { code:"AlfaSlabOne-Regular.ttf", name: "AlfaSlabOne-Regular", type: "eng", font: "AlfaSlabOne" },
    { code:"BebasNeue-Regular.ttf", name: "BebasNeue-Regular", type: "eng", font: "BebasNeue" },
    { code:"Blinker-Bold.ttf", name: "Blinker-Bold", type: "eng", font: "BlinkerBold" },
    { code:"Blinker-Regular.ttf", name: "Blinker-Regular", type: "eng", font: "BlinkerRegular" },
    { code:"Caveat-VariableFont_wght.ttf", name: "Caveat-VariableFont_wght", type: "eng", font: "CaveatVariableFont" },
    { code:"Montserrat-Bold.ttf", name: "Montserrat-Bold", type: "eng", font: "MontserratBold" },
    { code:"Montserrat-Italic.ttf", name: "Montserrat-Italic", type: "eng", font: "MontserratItalic" },
    { code:"Montserrat-Regular.ttf", name: "Montserrat-Regular", type: "eng", font: "MontserratRegular" },
    { code:"Orbitron-VariableFont_wght.ttf", name: "Orbitron-VariableFont_wght", type: "eng", font: "OrbitronVariableFont" },
    { code:"Poppins-Bold.ttf", name: "Poppins-Bold", type: "eng", font: "PoppinsBold" },
    { code:"Poppins-Italic.ttf", name: "Poppins-Italic", type: "eng", font: "PoppinsItalic" },
    { code:"Poppins-Regular.ttf", name: "Poppins-Regular", type: "eng", font: "PoppinsRegular" },
    { code:"PinyonScript-Regular.ttf", name: "PinyonScript-Regular", type: "eng", font: "PinyonScriptRegular" },
    { code:"SourceSerifPro-Bold.ttf", name: "SourceSerifPro-Bold", type: "eng", font: "SourceSerifProBold" },
    { code:"SourceSerifPro-Italic.ttf", name: "SourceSerifPro-Italic", type: "eng", font: "SourceSerifProItalic" },
    { code:"SourceSerifPro-Regular.ttf", name: "SourceSerifPro-Regular", type: "eng", font: "SourceSerifProRegular" }
]

export const fontSizeList = [
    { name: '24', value: 100 },
    { name: '32', value: 150 },
    { name: '40', value: 200 },
    { name: '48', value: 250 },
    { name: '56', value: 300 }
]

const initialText = {
    text: '',
    textStyle: {
        fontSize: 0,
        fontFamily: '',
        fontWeight: 500,
        color: '#FFFFFF',
        textAlign: 'left'
    },
    coordinates: {
        x: 0,
        y: 0
    },
    zIndex: -1,
    id: -1
}

export const projectContentInfo = createSlice({
    name,
    initialState,
    reducers: {

    },
    extraReducers: {
        [fetchProject.fulfilled.type] : (state, action) => {
            state.original = action.payload.project;

            let imageItem = [];

            action.payload.project.scenes[0].image_items.map((item, index) => {
                imageItem.push({
                    path: item.file_path,
                    coordinates: { x: item.x_shift, y: item.y_shift },
                    scale: item.scale,
                    zIndex: item.z_index,
                    focusId: index+1,
                    id: item.id
                })
            })

            state.images = imageItem;

            let textItem = [];

            action.payload.project.scenes[0].text_items.map((item, index) => {
                textItem.push({
                    text: item.text,
                    textStyle: {
                        fontScale: item.size,
                        fontFamily: item.font_name,
                        color: item.text_color
                    },
                    coordinates: {
                        x: item.x_shift,
                        y: item.y_shift,
                    },
                    zIndex: item.z_index,
                    focusId: -(index+1),
                    id: item.id
                })
            })

            state.texts = textItem;

        },
        [contentActions.deleteImageContent] : (state, action) => {
            const removeIdx = state.images.findIndex((item) =>  { if(item) return item.id === action.payload});
            state.images.splice(removeIdx,1);
        },
        [contentActions.deleteText]: (state, action) => {
            const removeIdx = state.texts.findIndex((item) => { if(item) return item.id === action.payload});
            state.texts.splice(removeIdx,1);
        },
        [fetchImage.fulfilled.type]: (state, action) => {
            let value = action.payload.upload_images;
            if(value[value.length-1].type === 'imageItem') {
                state.images.push({
                    path: value[value.length-1].file_path,
                    coordinates: { x: 0, y: 0 },
                    scale: 100,
                    zIndex: state.images.length+1,
                    focusId: state.images.length+1,
                    id: `new${state.images.length}`
                })
            }
        },
        [fetchImageByPdf.fulfilled.type]: (state, action) => {
            let value = action.payload.data.upload_images;
            if (action.payload.index === 0){
                state.images.push({
                    path: value[value.length-1].file_path,
                    coordinates: { x: 0, y: 0 },
                    scale: 100,
                    zIndex: state.images.length+1,
                    focusId: state.images.length+1,
                    id: `new${state.images.length}`
                })
            }
        },
        [fetchImageByPdf.rejected.type]: (state, action) => {
            let imageItem = [];
            action.payload.data.scenes[action.payload.index].image_items.map((item, index) => {
                imageItem.push({
                    path: item.file_path,
                    coordinates: { x: item.x_shift, y: item.y_shift },
                    scale: item.scale,
                    zIndex: item.z_index,
                    focusId: index+1,
                    id: item.id
                })
            })
            state.images = imageItem;
            let textItem = [];
            action.payload.data.scenes[action.payload.index].text_items.map((item, index) => {
                textItem.push({
                    text: item.text,
                    textStyle: {
                        fontScale: item.size,
                        fontFamily: item.font_name,
                        color: item.text_color
                    },
                    coordinates: {
                        x: item.x_shift,
                        y: item.y_shift,
                    },
                    zIndex: item.z_index,
                    focusId: -(index+1),
                    id: item.id
                })
            })
            state.texts = textItem;
        },
        [changeActions.changePos]: (state, action) => {
            if (action.payload.type === 'image'){
                const changeIdx = state.images.findIndex((item) => item.id === action.payload.data.id);
                state.images[changeIdx].coordinates = {x: action.payload.data.x, y: action.payload.data.y};
            }else if (action.payload.type === 'text'){
                const changeIdx = state.texts.findIndex((item) => item.id === action.payload.data.id);
                state.texts[changeIdx].coordinates = {x: action.payload.data.x, y: action.payload.data.y};
            }
        },
        [changeActions.changeSize]: (state, action) => {
            if (action.payload.type === 'image'){
                const changeIdx = state.images.findIndex((item) => item.id === action.payload.data.id);
                state.images[changeIdx].scale = action.payload.data.scale;
            } else if (action.payload.type === 'text'){
                const changeIdx = state.texts.findIndex((item) => item.id === action.payload.data.id);
                state.texts[changeIdx].scale = action.payload.data.scale;
            }
        },
        [slideActions.addSlide]: (state, action) => {
            let imageItem = [];
            let textItem = [];
            state.images = imageItem;
            state.texts = textItem;
        },
        [slideActions.setSlideIdx]: (state, action) => {
            let imageItem = [];

            action.payload.data.image_items.map((item, index) => {
                imageItem.push({
                    path: item.file_path,
                    coordinates: { x: item.x_shift, y: item.y_shift },
                    scale: item.scale,
                    zIndex: item.z_index,
                    focusId: index+1,
                    id: item.id
                })
            })

            let textItem = [];

            action.payload.data.text_items.map((item, index) => {
                textItem.push({
                    text: item.text,
                    textStyle: {
                        fontScale: item.size,
                        fontFamily: item.font_name,
                        color: item.text_color
                    },
                    coordinates: {
                        x: item.x_shift,
                        y: item.y_shift,
                    },
                    zIndex: item.z_index,
                    focusId: -(index+1),
                    id: item.id
                })
            })

            state.texts = textItem;
            state.images = imageItem;
        },
        [sceneActions.deleteScene]: (state, action) => {
            let imageItem = [];

            action.payload.data.image_items.map((item, index) => {
                imageItem.push({
                    path: item.file_path,
                    coordinates: { x: item.x_shift, y: item.y_shift },
                    scale: item.scale,
                    zIndex: item.z_index,
                    focusId: index+1,
                    id: item.id
                })
            })

            let textItem = [];

            action.payload.data.text_items.map((item, index) => {
                textItem.push({
                    text: item.text,
                    textStyle: {
                        fontScale: item.size,
                        fontFamily: item.font_name,
                        color: item.text_color
                    },
                    coordinates: {
                        x: item.x_shift,
                        y: item.y_shift,
                    },
                    zIndex: item.z_index,
                    focusId: -(index+1),
                    id: item.id
                })
            })

            state.texts = textItem;
            state.images = imageItem;
        },
        [sceneActions.resetScene]: (state, action) => {
            const originalItem = state.original.scenes.find((item) => item.id === action.payload.id);
            if (originalItem !== undefined) {
                const scene = JSON.parse(JSON.stringify(originalItem));

                let imageItem = [];

                scene.image_items.map((item, index) => {
                    imageItem.push({
                        path: item.file_path,
                        coordinates: { x: item.x_shift, y: item.y_shift },
                        scale: item.scale,
                        zIndex: item.z_index,
                        focusId: index+1,
                        id: item.id
                    })
                })
    
                let textItem = [];
    
                scene.text_items.map((item, index) => {
                    textItem.push({
                        text: item.text,
                        textStyle: {
                            fontScale: item.size,
                            fontFamily: item.font_name,
                            color: item.text_color
                        },
                        coordinates: {
                            x: item.x_shift,
                            y: item.y_shift,
                        },
                        zIndex: item.z_index,
                        focusId: -(index+1),
                        id: item.id
                    })
                })
    
                state.texts = textItem;
                state.images = imageItem;

            }
        },
        [contentActions.addText]: (state, action) => {
            state.texts.push({
                text: action.payload.text,
                textStyle: {
                    fontScale: 100,
                    fontFamily: 'NotoSansKR-Regular.otf',
                    color: '#ffffff'
                },
                coordinates: {
                    x: 0,
                    y: 0,
                },
                zIndex: state.texts.length+1,
                focusId: -(state.texts.length+1),
                id: `newT${state.texts.length}`
            })
        },
        [contentActions.changeText]: (state, action) => {
            state.texts[-(action.payload.id+1)].text = action.payload.text;
        },
        [contentActions.changeTextDetail]: (state, action) => {

            switch(action.payload.type) {
                case 'font': {
                    state.texts[-(action.payload.id+1)].textStyle.fontFamily = action.payload.data;
                    break;
                }
                case 'color': {
                    state.texts[-(action.payload.id+1)].textStyle.color = action.payload.data;
                    break;
                }
                case 'size': {
                    state.texts[-(action.payload.id+1)].textStyle.fontScale = action.payload.data;
                    break;
                }
            }
        },
        [reset]: (state, action) => {
            Object.assign(state, initialState);
        }
    }
})

export const imageList = (state) => state.creation.contentP.images;

export const textList = (state) => state.creation.contentP.texts;

export default projectContentInfo.reducer; 