import { createSlice } from "@reduxjs/toolkit";
import { backgroundActions, fetchImage, fetchProject, modelActions, reset, sceneActions, slideActions } from "../creaton";


const name = 'backgroundP';


const initialState = {
    backgroundPath : '#FFFFFF',
    backgroundResize : 'fill',
    original: '',
}

export const projectBgInfo = createSlice({
    name,
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchProject.fulfilled.type] : (state, action) => {
            state.original = action.payload.project;
            state.backgroundPath = action.payload.project.scenes[0].background_path;

            state.backgroundResize = action.payload.project.scenes[0].bg_resize_option
        },
        [fetchImage.fulfilled.type] : (state, action) => {
            let value = action.payload.upload_images;
            if(value[value.length-1].type === "background"){
                state.backgroundPath = value[value.length-1].file_path;
            }
        },
        [fetchImage.rejected.type] : (state, action) => {
            
        },
        [backgroundActions.changeBackground] : (state, action) => {
            state.backgroundPath = action.payload;
        },
        [backgroundActions.changeBgResize] : (state, action) => {
            state.backgroundResize = action.payload;   
        },
        [slideActions.setSlideIdx]: (state, action) => {
            state.backgroundPath = action.payload.data.background_path;
            state.backgroundResize = action.payload.data.bg_resize_option
        },
        [slideActions.addSlide]: (state, action) => {
            state.backgroundPath = action.payload.data.background_path;
            state.backgroundResize = action.payload.data.bg_resize_option
        },
        [sceneActions.deleteScene]: (state, action) => {
            state.backgroundPath = action.payload.data.background_path;
            state.backgroundResize = action.payload.data.bg_resize_option
        },
        [sceneActions.resetScene]: (state, action) => {
            const originalItem = state.original.scenes.find((item) => item.id === action.payload.id);
            if (originalItem !== undefined) {
                const scene = JSON.parse(JSON.stringify(originalItem));
                state.backgroundPath = scene.background_path;
                state.backgroundResize = scene.bg_resize_option
            }
        },
        [modelActions.changeStyle]: (state, action) => {
            if(action.payload.id === 28) {
            }
        },
        [reset]: (state, action) => {
            Object.assign(state, initialState);
        }
    }
})

export const backgroundPath = (state) => state.creation.background.backgroundPath;

export const backgroundResize = (state) => state.creation.background.backgroundResize;

export default projectBgInfo.reducer;