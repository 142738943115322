import { createSlice } from "@reduxjs/toolkit";
import { fetchProject, modelActions, reset, sceneActions, slideActions } from "../creaton";
import { fetchModel } from "./model";



const name = 'action';

const initialState = {
    action: -1,
    actions: [
        {
            imageSrc: '',
            name: '',
            id: -1,
            previewSrc: ''
        }
    ],
    original: [],
    originalPj: []
}

export const actionSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: {
        [fetchModel.pending.type]: () => {},
        [fetchModel.fulfilled.type]: (state, action) => {
            let styleArray = [];
            let actionArray = [];
            let modelIdArray = [];

            action.payload.user.ai_models.map((item) => {
                styleArray = styleArray.concat(item.styles);
            })

            styleArray.map((item) => {
                let tempArray = [];
                item.actions.map((action) => {
                    const temp = {...action}
                    temp['ai_model_id'] = item.ai_model_id;
                    tempArray.push(temp);
                })
                actionArray = actionArray.concat(tempArray);
            })

            state.original = actionArray;
            
            // let defaultAction = -1;
            // actionArray = [];
            // state.original.map((item) => {
            //     if(item.style_id === 1) {

            //         if (defaultAction === -1) {
            //             defaultAction = {
            //                 imageSrc: item.silhouette_img_path,
            //                 name: item.action_description,
            //                 id: item.id,
            //                 previewSrc: item.preview_path
            //             };
            //         }

            //         actionArray.push({
            //             imageSrc: item.silhouette_img_path,
            //             name: item.action_description,
            //             id: item.id,
            //             previewSrc: item.preview_path
            //         })
            //     }
            // })

            // state.actions = actionArray;
            // state.action = defaultAction;
        },
        [fetchProject.fulfilled.type]: (state , action) => {
            let actionArray = [];
            let styleId = -1;

            state.originalPj=action.payload.project;

            let defaultAction = -1;

            state.original.map((item) => {

                if (action.payload.project.scenes[0].ai_model_item.action.style.id === item.style_id){
                    
                    if (item.ai_model_id === action.payload.project.scenes[0].ai_model_item.action.style.ai_model_id) {

                        if (defaultAction === -1) {
                            defaultAction = {
                                imageSrc: item.silhouette_img_path,
                                name: item.action_description,
                                id: item.id,
                                previewSrc: item.preview_path
                            };
                        }    

                        actionArray.push({
                            imageSrc: item.silhouette_img_path,
                            name: item.action_description,
                            id: item.id,
                            previewSrc: item.preview_path
                        })
                        styleId = item.style_id;
                    }
                }
            })

            state.actions = actionArray;
            state.action = defaultAction;
        },
        [modelActions.changeModel] : (state, action) => {
            if(!action.payload.imageSrc) return

            let actionArray = [];
            let styleId = -1;

            let defaultAction = -1;

            state.original.map((item) => {
                if (styleId === -1 || styleId === item.style_id){
                    if (item.ai_model_id === action.payload.id) {

                        if (defaultAction === -1) {
                            defaultAction = {
                                imageSrc: item.silhouette_img_path,
                                name: item.action_description,
                                id: item.id,
                                previewSrc: item.preview_path
                            };
                        }    

                        actionArray.push({
                            imageSrc: item.silhouette_img_path,
                            name: item.action_description,
                            id: item.id,
                            previewSrc: item.preview_path
                        })
                        styleId = item.style_id;
                    }
                }
            })

            state.actions = actionArray;
            state.action = defaultAction;
        },
        [modelActions.changeStyle] : (state, action) => {
            let actionArray = [];

            let defaultAction = -1;

            state.original.map((item) => {
                if(item.style_id === action.payload.id) {

                    if (defaultAction === -1) {
                        defaultAction = {
                            imageSrc: item.silhouette_img_path,
                            name: item.action_description,
                            id: item.id,
                            previewSrc: item.preview_path
                        };
                    }    

                    actionArray.push({
                        imageSrc: item.silhouette_img_path,
                        name: item.action_description,
                        id: item.id,
                    previewSrc: item.preview_path
                    })
                }
            })

            state.actions = actionArray;
            state.action = defaultAction;
        },
        [slideActions.setSlideIdx] : (state, action) => {
            let actionArray = [];

            let defaultAction = -1;

            state.original.map((item) => {
                if(item.style_id === action.payload.data.ai_model_item.action.style.id) {

                    if (defaultAction === -1) {
                        defaultAction = {
                            imageSrc: item.silhouette_img_path,
                            name: item.action_description,
                            id: item.id,
                            previewSrc: item.preview_path
                        };
                    }    

                    actionArray.push({
                        imageSrc: item.silhouette_img_path,
                        name: item.action_description,
                        id: item.id,
                    previewSrc: item.preview_path
                    })
                }
            })

            state.actions = actionArray;
            state.action = defaultAction;
        },
        [slideActions.addSlide] : (state, action) => {
            let actionArray = [];

            let defaultAction = -1;

            state.original.map((item) => {
                if(item.style_id === action.payload.data.ai_model_item.action.style.id) {

                    if (defaultAction === -1) {
                        defaultAction = {
                            imageSrc: item.silhouette_img_path,
                            name: item.action_description,
                            id: item.id,
                            previewSrc: item.preview_path
                        };
                    }    

                    actionArray.push({
                        imageSrc: item.silhouette_img_path,
                        name: item.action_description,
                        id: item.id,
                    previewSrc: item.preview_path
                    })
                }
            })

            state.actions = actionArray;
            state.action = defaultAction;
        },
        [sceneActions.resetScene]: (state, action) => {


            const originalItem = state.originalPj.scenes.find((item) => item.id === action.payload.id);

            if(originalItem !== undefined) {
                const scene = JSON.parse(JSON.stringify(originalItem));
                let actionArray = [];
                let defaultAction = -1;
                state.original.map((item) => {
                    if(item.style_id === scene.ai_model_item.action.style.id) {
    
                        if (defaultAction === -1) {
                            defaultAction = {
                                imageSrc: item.silhouette_img_path,
                                name: item.action_description,
                                id: item.id,
                                previewSrc: item.preview_path
                            };
                        }    
    
                        actionArray.push({
                            imageSrc: item.silhouette_img_path,
                            name: item.action_description,
                            id: item.id,
                        previewSrc: item.preview_path
                        })
                    }
                })
    
                state.actions = actionArray;
                state.action = defaultAction;
            }
        },
        [reset]: (state, action) => {
            Object.assign(state, initialState);
        }
    }
})

export const actionList = (state) => state.creation.action.actions;
export const initAction = (state) => state.creation.action.action;

export default actionSlice.reducer;