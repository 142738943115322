import { createSlice } from "@reduxjs/toolkit";
import { captionOff, changeCaption, changeLang, changeScript, fetchProject, reset, resetAction, sceneActions, slideActions } from "./creaton";


const name = 'script';



const initialState = {
    text: '',
    lang: 'Korean',
    captionOn: false,
    original: []
}

export const scriptSlice = createSlice({
    name,
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchProject.fulfilled.type] : (state, action) => {
            state.lang = action.payload.project.scenes[0].lang;
            state.text = action.payload.project.scenes[0].script ? action.payload.project.scenes[0].script.replaceAll('<span style="padding: 2px 6px; background-color: #25b9f1;color: white;border-radius: 5px;font-weight: 600;" contenteditable="false">0.5</span> ', "{0.5}") : '';
            state.captionOn = action.payload.project.scenes[0].subtitle_item.is_rendering
            state.original = action.payload.project;
        },
        [changeLang]: (state, action) => {
            state.lang = action.payload;
        },
        [changeScript]: (state, action) => {
            state.text = action.payload;
        },
        [slideActions.setSlideIdx]: (state, action) => {
            state.lang = action.payload.data.lang;
            state.text = action.payload.data.script ? action.payload.data.script.replaceAll('<span style="padding: 2px 6px; background-color: #25b9f1;color: white;border-radius: 5px;font-weight: 600;" contenteditable="false">0.5</span> ', "{0.5}") : '';        
            state.captionOn = action.payload.data.subtitle_item.is_rendering
        },
        [slideActions.addSlide]: (state, action) => {
            state.lang = action.payload.data.lang;
            state.text = action.payload.data.script ? action.payload.data.script.replaceAll('<span style="padding: 2px 6px; background-color: #25b9f1;color: white;border-radius: 5px;font-weight: 600;" contenteditable="false">0.5</span> ', "{0.5}") : '';     
            state.captionOn = action.payload.data.subtitle_item.is_rendering
        },
        [sceneActions.deleteScene]: (state, action) => {
            state.lang = action.payload.data.lang;
            state.text = action.payload.data.script ? action.payload.data.script.replaceAll('<span style="padding: 2px 6px; background-color: #25b9f1;color: white;border-radius: 5px;font-weight: 600;" contenteditable="false">0.5</span> ', "{0.5}") : '';     
            state.captionOn = action.payload.data.subtitle_item.is_rendering
        },
        [sceneActions.resetScene]: (state, action) => {
            const originalItem = state.original.scenes.find((item) => item.id === action.payload.id);
            if (originalItem !== undefined) {
                const scene = JSON.parse(JSON.stringify(originalItem));
                state.lang = scene.lang;
                state.text = scene.script ? scene.script.replaceAll('<span style="padding: 2px 6px; background-color: #25b9f1;color: white;border-radius: 5px;font-weight: 600;" contenteditable="false">0.5</span> ', "{0.5}") : '';     
                state.captionOn = scene.subtitle_item.is_rendering
            }else {

            }

        },
        [changeCaption]: (state, action) => {
            state.captionOn = action.payload;
        },
        [reset]: (state, action) => {
            Object.assign(state, initialState);
        },
        [resetAction]: (state, action) => {
            const removeAction = (script, result='') => {
                const actStart = script.indexOf('(ACTION-START)');
                const actEnd = script.indexOf('(ACTION-END)');
                if (actStart!==-1 && actEnd !== -1){
                    result += script.substring(0,actStart);
                    const actionInfo = script.substring(actStart,actEnd);
                    const actionScript = actionInfo.split(':')[1];
                    result += actionScript;
                    return removeAction(script.substring(actEnd+'(ACTION-END)'.length), result)
                }
                result += script;
                return result;
            }
            state.text = removeAction(state.text)
        }
    }
});

export const lang = (state) => state.creation.script.lang;

export const script = (state) => state.creation.script.text;

export const caption = (state) => state.creation.script.captionOn;

export const {setScript} = scriptSlice.actions;

export default scriptSlice.reducer;