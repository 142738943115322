import { ImportantDevices } from "@mui/icons-material"

export const h1 = {
    fontFamily: 'Pretendard',
    fontWeight: 600,
    fontSize : '20px',
    lineHeight : '150%',
}

export const h2 = {
    fontFamily: 'Pretendard',
    fontWeight: 600,
    fontSize : '16px',
    lineHeight : '150%',
}

export const h3 = {
    fontFamily: 'Pretendard',
    fontWeight: 600,
    fontSize : '12px',
    lineHeight : '150%',
}

export const h4 = {
    fontFamily: 'Pretendard',
    fontWeight: 600,
    fontSize : '18px',
    lineHeight : '150%',
}

export const title1 = {
    fontFamily: 'Pretendard',
    fontWeight: 600,
    fontSize : '14px',
    lineHeight : '150%',
}

export const title2 = {
    fontFamily: 'Pretendard',
    fontWeight: 400,
    fontSize : '14px',
    lineHeight : '150%',
    
}

export const title3 = {
    fontFamily: 'Pretendard',
    fontWeight: 500,
    fontSize : '12px',
    lineHeight : '150%',
}

export const title4 = {
    fontFamily: 'Pretendard',
    fontWeight: 400,
    fontSize : '14px',
    lineHeight : '150%',
}
