import { createSlice } from "@reduxjs/toolkit";
import { fetchProject, modelActions, reset, sceneActions, slideActions } from "../creaton";
import { fetchModel } from "./model";

const name = 'style';

const initialState = {
    style: -1,
    styles: [
        {
            imageSrc: '',
            name: '',
            id: -1,
            previewSrc: ''
        }
    ],
    original: [],
    originalPj: []
}

export const styleSlice = createSlice({
    name,
    initialState,
    reducers:{
    },
    extraReducers: {
        [fetchModel.pending.type]: () => {},
        [fetchModel.fulfilled.type]: (state, action) => {
            let styleArray = [];
            action.payload.user.ai_models.map((item) => {
                styleArray = styleArray.concat(item.styles);
            })
            state.original = styleArray;

            // let defaultStyle = -1;

            // styleArray = [];
            // state.original.map((item) => {
            //     if (item.ai_model_id === 1){

            //         if (defaultStyle === -1) {
            //             defaultStyle = {
            //                 imageSrc: item.silhouette_img_path,
            //                 name: item.style_description,
            //                 id: item.id,
            //                 previewSrc: item.preview_path
            //             };
            //         }

            //         styleArray.push({
            //             imageSrc: item.silhouette_img_path,
            //             name: item.style_description,
            //             id: item.id,
            //             previewSrc: item.preview_path
            //         })
            //     }
            // })
            // state.styles = styleArray;
            // state.style = defaultStyle;
        },
        [fetchProject.fulfilled.type]: (state, action) => {
            let styleArray = [];
            let defaultStyle = -1;
            state.originalPj=action.payload.project;
            state.original.map((item) => {
                if (item.ai_model_id === action.payload.project.scenes[0].ai_model_item.action.style.ai_model_id){
                    
                    if (defaultStyle === -1) {
                        defaultStyle = {
                            imageSrc: item.silhouette_img_path,
                            name: item.style_description,
                            id: item.id,
                            previewSrc: item.preview_path
                        };
                    }

                    styleArray.push({
                        imageSrc: item.silhouette_img_path,
                        name: item.style_description,
                        id: item.id,
                        previewSrc: item.preview_path
                    })
                }
            })
            state.styles = styleArray;
            state.style = defaultStyle;
        },
        [modelActions.changeModel] : (state, action) => {
            if(!action.payload.imageSrc) return

            let styleArray = [];
            let defaultStyle = -1;
            state.original.map((item) => {
                if (item.ai_model_id === action.payload.id){
                    
                    if (defaultStyle === -1) {
                        defaultStyle = {
                            imageSrc: item.silhouette_img_path,
                            name: item.style_description,
                            id: item.id,
                            previewSrc: item.preview_path
                        };
                    }

                    styleArray.push({
                        imageSrc: item.silhouette_img_path,
                        name: item.style_description,
                        id: item.id,
                        previewSrc: item.preview_path
                    })
                }
            })
            state.styles = styleArray;
            state.style = defaultStyle;
        },
        [slideActions.setSlideIdx]: (state ,action) => {
            let styleArray = [];
            let defaultStyle = -1;

            state.original.map((item) => {
                if (item.ai_model_id === action.payload.data.ai_model_item.action.style.ai_model_id){
                    
                    if (defaultStyle === -1) {
                        defaultStyle = {
                            imageSrc: item.silhouette_img_path,
                            name: item.style_description,
                            id: item.id,
                            previewSrc: item.preview_path
                        };
                    }

                    styleArray.push({
                        imageSrc: item.silhouette_img_path,
                        name: item.style_description,
                        id: item.id,
                        previewSrc: item.preview_path
                    })
                }
            })
            state.styles = styleArray;
            state.style = defaultStyle;
        },
        [slideActions.addSlide]: (state, action) => {
            let styleArray = [];
            let defaultStyle = -1;

            state.original.map((item) => {
                if (item.ai_model_id === action.payload.data.ai_model_item.action.style.ai_model_id){
                    
                    if (defaultStyle === -1) {
                        defaultStyle = {
                            imageSrc: item.silhouette_img_path,
                            name: item.style_description,
                            id: item.id,
                            previewSrc: item.preview_path
                        };
                    }

                    styleArray.push({
                        imageSrc: item.silhouette_img_path,
                        name: item.style_description,
                        id: item.id,
                        previewSrc: item.preview_path
                    })
                }
            })
            state.styles = styleArray;
            state.style = defaultStyle;
        },
        [sceneActions.resetScene] : (state, action) => {

            const originalItem = state.originalPj.scenes.find((item) => item.id === action.payload.id);

            if(originalItem !== undefined) {
                const scene = JSON.parse(JSON.stringify(originalItem));
            
                let styleArray = [];
                let defaultStyle = -1;
                state.original.map((item) => {
                    if (item.ai_model_id === scene.ai_model_item.action.style.ai_model_id){
                        
                        if (defaultStyle === -1) {
                            defaultStyle = {
                                imageSrc: item.silhouette_img_path,
                                name: item.style_description,
                                id: item.id,
                                previewSrc: item.preview_path
                            };
                        }
    
                        styleArray.push({
                            imageSrc: item.silhouette_img_path,
                            name: item.style_description,
                            id: item.id,
                            previewSrc: item.preview_path
                        })
                    }
                })
                state.styles = styleArray;
                state.style = defaultStyle;
            }
        },
        [reset]: (state, action) => {
            Object.assign(state, initialState);
        }
    }
})

export const styleList = (state) => state.creation.style.styles;
export const initStyle = (state) => state.creation.style.style;

export default styleSlice.reducer;