import { createSlice } from "@reduxjs/toolkit";
import { changeActions, fetchProject, reset, sceneActions, slideActions } from "./creaton";


const name = 'slide';

const initialState = {
    slideIdx: 0
}

export const slideSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: {
        [fetchProject.pending.type] : (state, action) => {
            state.slideIdx = 0;
        },
        [slideActions.addSlide]: (state ,action) => {
            state.slideIdx +=1;
        },
        [slideActions.setSlideIdx]: (state, action) => {
            state.slideIdx = action.payload.index;
        },
        [sceneActions.deleteScene]: (state, action) => {
            state.slideIdx = action.payload.index;
        },
        [sceneActions.copyScene]: (state, action) => {
            state.slideIdx+=1;
        },
        [reset]: (state, action) => {
            Object.assign(state, initialState);
        }
    }
});

export const slideIdx = (state) => state.creation.slide.slideIdx;

export default slideSlice.reducer;