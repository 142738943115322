import React, { Suspense } from "react";
import ROUTES, { RenderRoutes } from "./RenderRoutes";
import SuspenseSpinner from "../components/SuspenseFallback";
import Tooltip from "@mui/material/Tooltip";
import { COLORS, DEFAULT_STANDARD } from "../colors/color";
import { ReactComponent as CnaiIcon } from "../assets/svg/cnai_symbol.svg";
import { title2 } from "../style/textStyle";
import Box from "@mui/material/Box";

export const routes = () => {
  return (
    <Suspense fallback={<SuspenseSpinner />}>
      {window.location.href &&
      window.location.href.includes("http://192.168") ? (
        <a
          href="https://forms.gle/NL9oDFiJJvJcBhQX6"
          target="_blank"
          rel="noreferrer"
        >
          <Tooltip
            title="해당 주소는 사내 QC용입니다. 사내에서만 접속할 수 있어요."
            arrow
            placement="top"
          >
            <div
              id="qcButton"
              style={{
                position: "fixed",
                bottom: "130px",
                right: "60px",
                zIndex: 20000,
                background: "white",
                padding: "8px 16px",
                borderRadius: "5px",
                border: `1px solid ${
                  localStorage.primaryColor ?? DEFAULT_STANDARD.primary
                }`,
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "26px",
                color: localStorage.primaryColor ?? DEFAULT_STANDARD.primary,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              🚴🏿 내 의견 등록하러 가기!
            </div>
          </Tooltip>
        </a>
      ) : (
        <></>
      )}

      <a href="mailto:business@cnai.ai" target="_blank" rel="noreferrer">
        <Tooltip
          title={<span style={title2}>영업팀에 문의</span>}
          arrow
          placement="top"
          PopperProps={{ style: { zIndex: 20001 } }}
        >
          <Box
            id="qcButton"
            sx={{
              position: "fixed",
              bottom: "50px",
              right: "60px",
              zIndex: 20000,
              background: "white",
              width: "60px",
              height: "60px",
              borderRadius: "20px",
              border: `1px solid ${COLORS.gray[20]}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              boxShadow:
                "0px 8px 16px 0px rgba(0, 27, 55, 0.1), 0px 4px 8px 0px rgba(2, 32, 71, 0.05)",
            }}
          >
            <CnaiIcon id="folderIcon" fill="black" />
          </Box>
        </Tooltip>
      </a>

      <RenderRoutes routes={ROUTES.allRoutes} />
    </Suspense>
  );
};
