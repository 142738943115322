import { createSlice } from "@reduxjs/toolkit";
import { changeActions, reset } from "./creaton";
import {ReactComponent as TextIcon} from '../../assets/svg/text.svg';


const name = 'project_tab';

const MODEL = 'AI 휴먼';
const ACTION = '동작';
const BACKGROUND = '배경';
const CONTENT = '콘텐츠';
// const TEXT = '텍스트';
const TEMPLATE = '템플릿';


export const TABS = {
    MODEL,
    ACTION,
    BACKGROUND,
    CONTENT,
    // TEXT,
    // TEMPLATE
}

export const tabInfo = {
    MODEL: '<span class="material-icons">person</span>',
    ACTION: '<span class="material-icons">accessibility_new</span>',
    BACKGROUND: '<span class="material-symbols-outlined">background_replace</span>',
    // TEXT: TextIcon,
    CONTENT: '<span class="material-icons">photo_filter</span>',
    // TEMPLATE: '<span class="material-symbols-outlined">photo_library</span>',
}

const initialState = {
    tab: MODEL
}

export const tabSlice = createSlice({
    name,
    initialState,
    reducers: {
        setTab: (
            state,
            action
        ) => {
            state.tab = action.payload;
        }
    },
    extraReducers: {
        [reset]: (state, action) => {
            Object.assign(state, initialState);
        },
        [changeActions.changeFocus]: (state, action) => {
            switch(true) {
                case (action.payload>=1): { //1이상 이미지 아이템
                    state.tab = CONTENT
                    break;
                }
                case (action.payload==0): { //0 모델
                    state.tab = MODEL
                    break;
                }
                case (action.payload<=-1): { //-1이하 텍스트 아이템 
                    state.tab = CONTENT
                    break;
                }

            }
        }
    }
});

export const tab = (state) => state.creation.tab.tab;

export const {setTab} = tabSlice.actions;

export default tabSlice.reducer;