import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DEFAULT_STANDARD } from "../colors/color";
import api from "../lib/api";

const name = 'auth';

export const fetchAuth = createAsyncThunk(
    `${name}/fetchAuth`,
    async (req, thunkAPI) => {
        try {
            return (await api.loginWithData({email: req.email, password: req.password})).data;
        } catch (e) {
            return thunkAPI.rejectWithValue(await e.response.data);
        }
    }
)

const initialState = {
}

export const authSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: {
        [fetchAuth.pending.type]: (state, action) => {},
        [fetchAuth.fulfilled.type]: (state, action) => {
            localStorage.setItem('accessToken',action.payload.data.accessToken);
            localStorage.setItem('email',action.payload.user.email);
            if(action.payload.user.username) localStorage.setItem('username',action.payload.user.username);
            localStorage.setItem('logoPath',action.payload.user.setting && action.payload.user.setting.logo_path ? action.payload.user.setting.logo_path : 'https://cnaihumanstudio.s3.ap-northeast-2.amazonaws.com/logos/cn_logo.png');
            localStorage.setItem('serviceName',action.payload.user.setting && action.payload.user.setting.service_name ? action.payload.user.setting.service_name : 'CNAI');
            localStorage.setItem('themeId',action.payload.user.setting && action.payload.user.setting.color_theme.id ? action.payload.user.setting.color_theme.id : '-1');
            localStorage.setItem('themeName',action.payload.user.setting && action.payload.user.setting.color_theme.theme_name ? action.payload.user.setting.color_theme.theme_name : 'purple');
            localStorage.setItem('primaryColor',action.payload.user.setting && action.payload.user.setting.color_theme.primary_color ? action.payload.user.setting.color_theme.primary_color : DEFAULT_STANDARD.primary);
            localStorage.setItem('secondaryColor',action.payload.user.setting && action.payload.user.setting.color_theme.secondary_color ? action.payload.user.setting.color_theme.secondary_color : '#E7DEFF');
            localStorage.setItem('resolution',action.payload.user.setting && action.payload.user.setting.resolution && action.payload.user.setting.resolution!=='' ? action.payload.user.setting.resolution : '4K UHD');
        },
        [fetchAuth.rejected.type]: (state, action) => {
            alert(action.payload.message);
        },
    }
})

export default authSlice.reducer;