import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import Select from '@mui/material/Select';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';


import DownloadIcon from '@mui/icons-material/Download';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import WidthFullOutlinedIcon from '@mui/icons-material/WidthFullOutlined';

import FolderOpenIcon from '../../assets/svg/folder.svg';
import HomeIcon from '../../assets/svg/home.svg';

import LogoutIcon from '@mui/icons-material/Logout';
import { styled, Tab, Snackbar, Alert, Slide } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import icon from '../../assets/img/logo.svg';
import { COLORS, DEFAULT_STANDARD } from '../../colors/color';
import { fetchAuth } from '../../slice/auth';
import { location, PAGES, setLocation, setSnackBarOpen } from '../../slice/page';
import { btn1, btn3 } from '../../style/btnStyle';
import { h1, h2, h3, h4, title1, title2, title3 } from '../../style/textStyle';
import api from '../../lib/api';
import {Oval} from  'react-loader-spinner'
import Modal from '@mui/material/Modal';
import { fetchLipsync, fetchThumbnail, folderName, project, projectId, projectName, setSmartWords, saveProject, smartWords, thumbNail, thumbnailRef, changeOpposite, orientation, setProjectName, setFolder } from '../../slice/creation/creaton';
import { slideIdx } from '../../slice/creation/slide';
import { findAllByDisplayValue } from '@testing-library/react';
import { useState } from 'react';
import { fetchFolders, folders } from '../../slice/projects';
import { useEffect } from 'react';
import html2canvas from 'html2canvas-objectfit-fix';
import { Close } from '@mui/icons-material'
import CheckIcon from '@mui/icons-material/Check';


export const GNB_HEIGHT = 52;

export function GNB() {

    const Logout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('email');
        localStorage.removeItem('username');
        localStorage.removeItem('logoPath');
        localStorage.removeItem('serviceName');
        localStorage.removeItem('themeId');
        localStorage.removeItem('themeName');
        localStorage.removeItem('primaryColor');
        localStorage.removeItem('secondaryColor');
        if(document.querySelector('#qcButton')) { //QC 버튼 색상 변경
          document.querySelector('#qcButton').style.color = DEFAULT_STANDARD.primary
          document.querySelector('#qcButton').style.borderColor = DEFAULT_STANDARD.primary
        }
        navigate('/iamcnaistudio')
    }

    
    
    const GNB_Height = GNB_HEIGHT+'px'

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }

    const dispatch = useDispatch();

    const path = useSelector(location);

    const loc = useLocation();

    const query = qs.parse(loc.search, {
        ignoreQueryPrefix: true
    });

    React.useLayoutEffect(() => {
        if((!localStorage.logoPath || !localStorage.serviceName || !localStorage.themeId || !localStorage.themeName || !localStorage.primaryColor || !localStorage.secondaryColor) && (path!==PAGES.INTRODUCE)){
            Logout();
        }
    },[])

    const [selectedIndex, setSelectedIndex] = React.useState(0);

    React.useLayoutEffect(() => {
        if(path==PAGES.INTRODUCE){
            if(query && query.plan) {
                setSelectedIndex(1)
              }else{
                setSelectedIndex(0)
              }
        }else{
            setSelectedIndex(-1)
        }
      }, [query.plan])

    const navigate = useNavigate();

    const handleNav = (location = 'summary') => {
        dispatch(setLocation(location));
        navigate(`/${location}`)
    }

    const MakeNewProject = () => {
        api.makeNewProject({folderId:query.folderId}).then((response)=>{
            if(response.data){
                navigate(`/project/${response.data.project.id}`)
            }else{
                alert('새 프로젝트 생성에 실패하였습니다.')
            }
        }).catch((err)=>{
            if(err.response.data.message){
                alert(err.response.data.message)
            }else{
                alert('새 프로젝트 생성에 실패하였습니다.')
            }
        })
    }


    return (
        <Box sx={{ flexGrow: 1, width:'100%' }}>
            <AppBar position="fixed" elevation={0} style={{display:'flex', justifyContent: 'center', overflow:'auto', backgroundColor: COLORS.gray[80]}}>
                <Toolbar sx={{background: COLORS.gray[80]}} style={{minHeight: GNB_Height, minWidth:'800px'}}>
                    <Box onClick={()=> {handleNav('summary')}} sx={{display:'flex', flexDirection:'row', justifyContent: 'center', alignItems:'center', cursor:'pointer'}}>
                        <Icon style={{textAlign:'start', display: 'flex', alignItems: 'center', maxWidth: '32px', maxHeight: '32px', marginLeft:'16px' }}>
                            <img loading="lazy" style={{width:'100%',height:'100%', objectFit: 'contain'}} src={localStorage.logoPath ? localStorage.logoPath : 'https://cnaihumanstudio.s3.ap-northeast-2.amazonaws.com/logos/cn_logo.png'}/>
                        </Icon>
                        <Divider orientation='vertical' variant='middle' flexItem sx={{ ml:'15px', mr: '15px', width: '2px', height: '11px', mt: 'auto', mb: 'auto', background: COLORS.gray[50]}}/>
                        
                        <span style={{marginRight:'104px'}}>
                            <span style={{fontWeight:700, fontSize:'17px', lineHeight:'32px', color: COLORS.gray[50] }}>{localStorage.serviceName ? localStorage.serviceName : 'CNAI'}</span>
                            <span style={{fontWeight:700, fontSize:'10px', lineHeight:'32px', color: COLORS.gray[50], marginLeft:'2px' }}>STUDIO</span>
                        </span>
                        
                    </Box>

                    <BtnBox active={selectedIndex==0} onClick={() => {handleNav(PAGES.INTRODUCE); window.scrollTo(0,0);}} margin={'0 52px 0 0'} text={'스튜디오 소개'} color={COLORS.gray[50]} activeColor={localStorage.primaryColor ? localStorage.primaryColor : DEFAULT_STANDARD.primary} />
                    {/* <BtnBox active={path==PAGES.INFORMATION} onClick={() => {handleNav(PAGES.INFORMATION)}} margin={'0 52px 0 0'} text={'이용 안내'} color={COLORS.gray[50]} activeColor={localStorage.primaryColor} /> */}
                    {/* <BtnBox active={selectedIndex==1} onClick={(e) => { if(selectedIndex==1){const element = document.getElementById("plan"); element.scrollIntoView({behavior: "smooth"});} else{handleNav(PAGES.INTRODUCE+'?plan=true')}  }} margin={'0'} text={'요금제'} color={COLORS.gray[50]} activeColor={localStorage.primaryColor ? localStorage.primaryColor : DEFAULT_STANDARD.primary} /> */}

                    <Box sx={{ flexGrow: 1 }}/>


                    {
                        localStorage.accessToken ? (
                        <div style={{marginRight:'5px'}}>
                            <Btn1 onClick={MakeNewProject} color={COLORS.white} bgColor={localStorage.primaryColor ? localStorage.primaryColor : DEFAULT_STANDARD.primary} activeColor={localStorage.primaryColor ? localStorage.primaryColor : DEFAULT_STANDARD.primary} icon={<NoteAddIcon sx={{width:'18px', height:'18px'}} />} text={'새 프로젝트'} />
                            <IconButton onClick={handleClick} sx={{ ml: '24px', padding: '0px' }}>
                                { localStorage.username ? (<Avatar variant="circular" sx={{ width: '34px', height: '34px', fontSize: '12px', background:'none', color:'white', border:'1px solid white'}}>{ (localStorage.username.length > 2) ? localStorage.username.substring(localStorage.username.length-2) : localStorage.username }</Avatar>) : (<Avatar variant="circular" sx={{ width: '34px', height: '34px', fontSize: '12px'}}></Avatar>)}  
                            </IconButton>
                            <MeunBox anchorEl={anchorEl} handleClose={handleClose} />
                        </div>) 
                        : (<IconButton onClick={() => handleNav('login')} sx={{ ml: '24px', padding: '0px', marginRight:'5px' }}>
                            <Avatar variant="circular" sx={{ width: '34px', height: '34px', fontSize: '10px', background:'none', color:'white', border:'1px solid white'}}>로그인</Avatar>
                           </IconButton>)
                    }
                </Toolbar>
            </AppBar>
        </Box>
    );
    

}

export function PROJECT_GNB() {

    const navigate = useNavigate();

    const projectInfo = useSelector(project);
    const dispatch = useDispatch();

    const SmartWords = useSelector(smartWords);

    const sceneIdx = useSelector(slideIdx);

    const checkScript = () => {
        let allow = [];
        projectInfo.scenes.map((item, index) => {
            if (!item.script || item.script === '') {
                allow.push(index+1);
            }
        })
        return allow
    }

    const checkAct = () => {
        let allow = [];
        projectInfo.scenes.map((item, index) => {
            if (!emptyActCheck(item.script)) {
                allow.push(index+1);
            }
        })
        return allow
    }

    const photo = useSelector(thumbNail);

    const emptyActCheck = (script = '', result = '') => {
        const actionStartIndex = script.indexOf('(ACTION-START)')
        const actionEndIndex = script.indexOf('(ACTION-END)')

        if (actionStartIndex!==-1){
            result+=script.substring(0,actionStartIndex);

            let tempScript = script.substring(actionStartIndex);

            let actionScript = tempScript.split(':')[1].substring(0,tempScript.split(':')[1].indexOf('(ACTION-END)'));

            if (actionScript.length<1){return false};
            result+=actionScript;
            return emptyActCheck(script.substring(actionEndIndex+'(ACTION-END)'.length),result)
        }
        result += script;
        return true;
    }

    const handleClick = () => {


        if(checkScript().length === 0){
            if(checkAct().length === 0){
                handleSave(() => {dispatch(fetchLipsync({project:projectInfo, smartWords: JSON.parse(SmartWords), useDfl:false, random: null, sceneSections: null, volume: 'normal', resolution: localStorage.resolution ?? '4K UHD' })).unwrap().catch((e) => {
                    handleClose2()
                })}, false);
                handleOpen2();
            }else {
                alert(`${checkAct()}번째 씬에 대본이 입력되지 않은 동작이 있어 생성할 수 없습니다.`);
            } 
        }else {
            alert(`${checkScript()}번째 씬의 대본이 비어있어 생성할 수 없습니다.`);
        }
    }
    


    const pjId = useSelector(projectId);

    const pjName = useSelector(projectName)

    const setProjectName = async () => {
        try{
            const response = await api.changeProjectName({projectId: pjId, projectName: pjName})

            if(response.data){

            }else{
              alert('이름 변경에 실패하였습니다.')
            }
          }catch{
            alert('이름 변경에 실패하였습니다.')
          }
    }

    const handleSave = (out=null, isSave = true) => {        
        if (sceneIdx === 0) {
            html2canvas(document.getElementById('canvas'),{
                allowTaint : true,
                useCORS: true,
                logging: true
            }).then((canvas) => {
                const photo = canvas.toDataURL();
                fetchPjThumbnail(photo, out, isSave);
            })
        }else {
            fetchPjThumbnail(photo, out, isSave);
        }
    }

    const fetchPjThumbnail = (base64file, out=null, isSave) => {

        var blobBin = atob(base64file.split(',')[1]);	// base64 데이터 디코딩
        var array = [];
        for (var i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
        }
        var file = new Blob([new Uint8Array(array)], {type: 'image/png'});	// Blob 생성

        var frm = new FormData();
        frm.append("projectId", pjId);
        frm.append("type", "project");
        frm.append("uploadImage", file);

        dispatch(fetchThumbnail(frm)).then(() => {
            if (isSave){
                if(out){
                    dispatch(saveProject({project:projectInfo})).then(() => {
                        out();
                    })
                }else {
                    dispatch(saveProject({project:projectInfo}));
                }
            }else {
                if(out) out();
            }
        });
    }

    const GNB_Height = GNB_HEIGHT+'px'

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        height: 229,
        bgcolor: COLORS.gray[70],
        border: 'none !important',
        outline: 'none !important',
        borderRadius:'2px',
        zIndex: 10000
      };
      

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position='static' sx={{display:'flex', justifyContent: 'center'}}>
                <Toolbar sx={{background: COLORS.gray[80]}} style={{ minHeight: GNB_Height, paddingLeft:'31.4px'}}>
                    <Icon style={{textAlign:'start', display: 'flex', alignItems: 'center', maxWidth: '19.2px', maxHeight: '19.2px', cursor:'pointer' }} onClick={handleOpen}>
                        <img loading="lazy" style={{width:'100%',height:'100%', objectFit: 'contain'}} src={HomeIcon}/>
                    </Icon>
                    
                    <Box sx={{ flexGrow: 1 }}/>

                    <TitleProject/>

                    <OrientationSelect/>
                    <BorderBtnInnerBlank onClick={handleSave} color={COLORS.white} bgColor={COLORS.gray[80]} activeColor={COLORS.gray[80]} text={'저장'} />
                    <div style={{position:'relative'}}>
                        <Btn1 style={{position:'absolute'}} color={COLORS.white} bgColor={localStorage.primaryColor} activeColor={localStorage.primaryColor} icon={<DownloadIcon sx={{fontSize: '16px'}} />} text={'영상 생성'} onClick={handleClick} />
                        <div id="loadingIcon" style={{position:'absolute', width:'96px', height:'34px', background:localStorage.primaryColor, display:'none', justifyContent:'center', alignItems:'center', top:0, borderRadius:'3px' }}><Oval color={'white'} secondaryColor={'white'} width={'24px'} height={'24px'} strokeWidth={5} /></div>
                    </div>
                </Toolbar>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box style={{display: 'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>
                            <span style={{fontWeight:600, fontSize:'18px', lineHeight:'27px', color: 'white'}}>종료하시겠습니까?</span>
                            <Box style={{display:'flex', marginTop:'50px'}}>
                                <Box style={{display:'flex', justifyContent:'center', alignItems:'center', border:`1px solid ${COLORS.gray[40]}`, width:'90px', height:'34px', borderRadius:'2px', cursor:'pointer'}} onClick={()=> navigate(`/summary`)}>
                                    <span style={{fontWeight:600, fontSize:'12px', lineHeight:'18px', color: COLORS.gray[40], marginTop:'-2px' }}>저장 안 함</span>
                                </Box>
                                <Box style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90px', height:'34px', borderRadius:'2px', background:localStorage.primaryColor, marginLeft:'20px', cursor:'pointer'}} onClick={()=> {handleSave(() => {navigate(`/summary`)});}}>
                                    <span style={{fontWeight:600, fontSize:'12px', lineHeight:'18px', color: 'white', marginTop:'-2px' }}>저장 후 종료</span>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
                <Modal
                    open={open2}
                    onClose={handleClose2}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box style={{display: 'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%', height:'100%'}}>
                            <span style={{fontWeight:600, fontSize:'18px', lineHeight:'27px', color: 'white'}}>영상 생성을 시작합니다.</span>
                            <Box style={{display:'flex', marginTop:'50px'}}>
                                <Box style={{display:'flex', justifyContent:'center', alignItems:'center', border:`1px solid ${COLORS.gray[40]}`, width:'90px', height:'34px', borderRadius:'2px', cursor:'pointer'}} onClick={()=> navigate(`/history?page=1&searchText=`)}>
                                    <span style={{fontWeight:600, fontSize:'12px', lineHeight:'18px', color: COLORS.gray[40], marginTop:'-2px' }}>종료</span>
                                </Box>
                                <Box style={{display:'flex', justifyContent:'center', alignItems:'center', width:'90px', height:'34px', borderRadius:'2px', background:localStorage.primaryColor, marginLeft:'20px', cursor:'pointer'}} onClick={handleClose2}>
                                    <span style={{fontWeight:600, fontSize:'12px', lineHeight:'18px', color: 'white', marginTop:'-2px' }}>작업 이어하기</span>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </AppBar>
        </Box>
    )
} 

// H

export function H1(props) {
    return (
        <div style={{
            color : props.color,
            margin: props.margin,
            h1
        }} >{props.text}</div>
    )
}

export function H2(props) {
    return (
        <div style={{
            h2,
            color : props.color,
            margin: props.margin
        }} >{props.text}</div>
    )
}

export function H3(props) {
    return (
        <div style={{
            h3,
            color : props.color,
            margin: props.margin
        }} >{props.text}</div>
    )
}

export function H4(props) {
    return (
        <div style={{
            h4,
            color : props.color,
            margin: props.margin
        }} >{props.text}</div>
    )
}

// title

export function TitleBox(props) {

    return (
        <Box style={title1} sx={{mr: '104px'}}>
            <div>{props.title}</div>
        </Box>
    )

}

export function TitleProject(props) {

    const pjName = useSelector(projectName);
    const fName = useSelector(folderName);

    const pjId = useSelector(projectId);

    const [isChange, setChange] = useState(null);

    const [anchorEl, setAnchorEl] = useState(null);
        
    const folderList = useSelector(folders);

    const dispatch = useDispatch();
    
    const handleChange = (value) => {
     
    }

    const handleClicks = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
        setChange(null);
    }

    const handleBlur = (e) => {
        if(e.target.innerText.length >15) {
            alert('15자 이상 입니다.');
        } else if (e.target.innerText.length <= 0) {
            alert('0자 이하 입니다.')
            e.target.innerText = pjName;
        } else {
            dispatch(setProjectName(e.target.innerText));
            e.target.contentEditable = false;


            setChange(null);
        }
    }


    
    // const handleFocus = (e) => {
    //     const div = document.getElementById('pjName');
    //     if (div.contentEditable) {
    //         if (document.body.createTextRange){
    //             var range = document.body.createTextRange();
    //             range.moveToElementText(div);
    //             range.select();
    //         }else {
    //             var range = document.createRange();
    //             range.selectNodeContents(div);
    //             var sel = window.getSelection();
    //             sel.removeAllRanges();
    //             sel.addRange(range);
    //         }
    //     }
    // }

    const onChange = (type = 'folder') => {
        if (type !== isChange){
            switch(type) {
                case 'folder':{
                    setChange('folder')
                    break;
                }
                case 'project': {
                    setChange('project')
                    
                    // if (document.body.createTextRange){
                    //     var range = document.body.createTextRange();
                    //     range.moveToElementText(div);
                    //     range.select();
                    // }else {
                    //     var range = document.createRange();
                    //     range.selectNodeContents(div);
                    //     var sel = window.getSelection();
                    //     sel.removeAllRanges();
                    //     sel.addRange(range);
                    // }
                    break;
                }
            }
        }
    }

    useEffect(() => {
        if (isChange==='project'){
            const div = document.getElementById('pjName');
            if (div) div.focus();
        }
    }, [isChange])

    return (
        <Box style={title1} sx={{display:'flex', alignItems: 'center', flexDirection:'row', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>


            {
                isChange === 'project'
                ? <></>
                : <Icon style={{textAlign:'start', display: 'flex', alignItems: 'center', maxWidth: '16.9px', maxHeight: '12.48px' }}>
                    <img loading="lazy" style={{width:'100%',height:'100%', objectFit: 'contain'}} src={FolderOpenIcon}/>
                </Icon>
            }  
            {
                isChange === 'project'
                ? <></>
                : <Box sx={{width:'8px'}}/>
            }

            {
                isChange === 'project'
                ? <></>
                : <div onClick={(e) => {onChange('folder');handleClicks(e);}} style={{color: COLORS.gray[50], cursor:'pointer'}}>{fName}</div>
            }

            <Menu
                id="folder-menu"
                elevation={0}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)} 
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{horizontal: 'center', vertical: 'top'}} 
                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                PaperProps={{
                    sx: {
                    backgroundColor: COLORS.gray[100],
                    color: COLORS.gray[40],
                    minWidth: '108px',
                    maxHeight: '152px',
                    padding: '4px 0',
                    marginLeft:'0px',
                    marginTop:'8px',
                    '&::-webkit-scrollbar': {
                      display: 'none',
                  }
                }
            }}>
                {
                    folderList?.length > 0
                    ? folderList && folderList?.map((item) => {
                        return  <MenuItem style={btn3} sx={{padding:'8px 18px', '&:hover' : {
                            backgroundColor: localStorage.primaryColor,
                            color: 'white'
                        }}}
                        onClick={() => {
                            dispatch(setFolder({id:item.id, name: item.folder_name}))
                        }}
                        >
                            {item.folder_name}
                        </MenuItem>
                    })
                    : <>{
                        dispatch(fetchFolders())
                    }
                    </>
                }

            </Menu>

            {
                isChange === 'project'
                ? <></>
                : <Box sx={{width:'16px'}}/>
            }

            
            {
                isChange === null
                ? <div style={{color: COLORS.gray[40]}}>/</div>
                : <></>
            }
            
            {
                isChange === 'project'
                ? <></>
                : <Box sx={{width:'16px'}}/>
            }

            {
                isChange === 'folder'
                ? <></>
                : <div id='pjName' contentEditable={isChange==='project'} 
                onKeyDown={(e) => {
                    if (e.key==="Enter") {
                        e.preventDefault();
                        e.currentTarget.blur();
                    }
                }}
                onFocus={(e) => {
                    if (document.body.createTextRange){
                        var range = document.body.createTextRange();
                        range.moveToElementText(e.target);
                        range.select();
                    }else {
                        var range = document.createRange();
                        range.selectNodeContents(e.target);
                        var sel = window.getSelection();
                        sel.removeAllRanges();
                        sel.addRange(range);
                    }
                }}
                onBlur={(e) => {handleBlur(e);}} onClick={() => {onChange('project')}} style={{
                    color: COLORS.white, 
                    cursor:'auto',
                    outline: '0px solid transparent',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    display:'inline-block',
                }}>{pjName}</div>
            }
            

        </Box>
    )
}

// Select

export function OrientationSelect(props) {

 

    const isVertical = useSelector(orientation);
    const dispatch = useDispatch();

    const handleChange = (event) => {
        if (isVertical !== event.target.value){
            dispatch(changeOpposite());
        }
    }

    return <Select 
        style={btn1}
        value={isVertical}
        onChange={handleChange}
        id='selectTab'
        onOpen={() => {document.getElementById('selectTab').parentElement.classList.add('Mui-focused');document.getElementById('selectTab').focus();}}
        onClose={() => {document.getElementById('selectTab').parentElement.classList.remove('Mui-focused');document.getElementById('selectTab').focus(); }}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{
            background: COLORS.gray[80],
            height: '34px',
            color: COLORS.gray[40],
            '& .MuiSvgIcon-root' : {
                color: COLORS.gray[40],
                right: '2px'
            },
            '& .MuiSelect-select' : {
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '11px',
                paddingRight: '29px !important',
                '& .MuiSvgIcon-root' : {
                    transform: 'scale(0.943,0.75)'
                }
            },

            '& .MuiOutlinedInput-notchedOutline' : {
                border: `1px solid ${COLORS.gray[60]}`
            },

            '&:hover':{
                textDecoration: 'none',
                backgroundColor: 'rgba(122, 89, 253, 0.04)',
                "&& fieldset": {
                    border: `1px solid ${COLORS.gray[60]}`
                }
            }


        }}
        MenuProps={{
            PaperProps: {
                sx: {
                    backgroundColor:COLORS.gray[100],
                    color: COLORS.gray[40],
                  "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: localStorage.primaryColor,
                    color:COLORS.white
                  },
                  "& .MuiMenuItem-root:hover": {
                    backgroundColor: localStorage.primaryColor,
                    color:COLORS.white
                  },
                  "& .MuiMenuItem-root.Mui-selected:hover": {
                    backgroundColor: localStorage.primaryColor,
                    color:COLORS.white
                  }
                }
            }
        }}>
            <MenuItem style={btn1} value="landscape">가로형<WidthFullOutlinedIcon style={{width: '24px', height: '24px', transform: 'scale(0.943,0.75)'}} sx={{ml: '3px'}} /></MenuItem>
            <MenuItem style={btn1} value="portrait">세로형<WidthFullOutlinedIcon style={{width: '24px', height: '24px', transform: 'rotate(-90deg) scale(0.943,0.75)'}} sx={{ml: '3px'}} /></MenuItem>

    </Select>
}


// btn

export function BorderBtnInnerBlank(props) {

    const navigate = useNavigate();
    
    return (
        <Button style={btn1} sx={{ 
            background: props.bgColor, 
            height: '34px',
            color : props.color,
            padding: '8px 12px 8px 12px',
            border: `1px solid ${COLORS.gray[60]}`,
            borderRadius: '2px',
            minWidth: '0px',
            mr: '12px',
            ml: '12px',
        }} onClick={props.onClick} >{props.text}</Button>
    )
}

export function Btn1(props) {


    return (
        <Button style={btn1} sx={{ 
            background: props.bgColor,
            width: props.width ? props.width : null,
            height: props.height ? props.height : '34px',
            color : props.color,
            padding: props.padding ? props.padding : '10px 14px 10px 14px',
            "& .MuiButton-startIcon" : { 
                marginRight: "8px", 
                marginLeft: "0px",
                "& .MuiSvgIcon-fontSizeMedium" : {
                    fontSize: "16px"
                }
            },
            "&:hover" : {
                background: props.activeColor
            }

        }} onClick={props.onClick} variant='raised' startIcon={props.icon}>{props.text}</Button>
    )
}

export function BtnBox(props) {
    return (
        <Button onClick={props.onClick} variant="text" color="primary" style={h2} sx={{
            color : props.active ? props.activeColor : props.color,
            margin: props.margin,
            "&:hover" : {
                color: props.activeColor
            }
        }}>
          {props.text}
        </Button>
    )
}


// Account Menu
export function MeunBox(props){

    const navigate = useNavigate();

    const Logout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('email');
        localStorage.removeItem('username');
        localStorage.removeItem('logoPath');
        localStorage.removeItem('serviceName');
        localStorage.removeItem('themeId');
        localStorage.removeItem('themeName');
        localStorage.removeItem('primaryColor');
        localStorage.removeItem('secondaryColor');
        if(document.querySelector('#qcButton')) { //QC 버튼 색상 변경
          document.querySelector('#qcButton').style.color = DEFAULT_STANDARD.primary
          document.querySelector('#qcButton').style.borderColor = DEFAULT_STANDARD.primary
        }
        navigate('/iamcnaistudio')
    }

    const [screenSetting, setScreenSetting] = useState('N');

    useEffect(()=>{
        api.loadUserProfile().then(res=>{
            setScreenSetting(res.data.user.ttv_subscriptions[0].screen_setting)
        })
    },[])

    return <Menu 
                id="account-menu"
                elevation={0}
                anchorEl={props.anchorEl} 
                open={Boolean(props.anchorEl)} 
                onClose={props.handleClose} 
                onClick={props.handleClose}
                transformOrigin={{horizontal: 'right', vertical: 'top'}} 
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                PaperProps={{
                    sx: {
                    bgcolor: COLORS.gray[80],
                    color: COLORS.gray[40],
                    mt: '14px',
                    minWidth: '180px',
                    padding: '4px 0'
                }
            }}>
                
            <Box style={title2} sx={{padding:'0 23px'}}>
                { (localStorage.username && localStorage.username!=='null' && localStorage.username!=='undefined') ? localStorage.username : '사용자명'}
            </Box>
            <Box style={title3} sx={{padding:'0 23px', marginBottom:'8px'}}>
                {localStorage.email}
            </Box>
            <Divider
              variant="fullWidth"
              orientation="horizontal"
              sx={{borderColor: COLORS.gray[70]}}
              />
               { screenSetting=='Y' ? (<MenuItem style={btn3} onClick={()=>navigate('/settings/admin')} 
            
            sx={{padding:'8px 23px', '&:hover' : {
                backgroundColor: localStorage.primaryColor,
                color:'white'
            }}}>
                Studio 설정
            </MenuItem>) : null }
            <MenuItem onClick={()=>navigate('/settings/password')} style={btn3} sx={{padding:'8px 23px', '&:hover' : {
                backgroundColor: localStorage.primaryColor,
                color:'white'
            }}}>
                비밀번호 설정
            </MenuItem>
            <MenuItem style={btn3} sx={{padding:'8px 23px', '&:hover' : {
                backgroundColor: localStorage.primaryColor,
                color:'white'
            }}}
            onClick={Logout} 
            >
                <LogoutIcon sx={{width:'12px', height:'12px', mr:'6px'}} /> 로그아웃
            </MenuItem>
    </Menu>
}


export const DefaultTabs = styled((props) => (
    <Tabs
      {...props}
      style={{
        height:'40px'
      }}
      sx={{
        WebkitBoxShadow: `inset 0 -2px 0 ${COLORS.gray[70]}`,
        MozBoxShadow: `inset 0 -2px 0 ${COLORS.gray[70]}`,
        boxShadow: `inset 0 -2px 0 ${COLORS.gray[70]}`,
        minHeight: '40px',
      }}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: COLORS.gray[70],
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: '20px',
      width: '100%',
      backgroundColor: COLORS.white,
    },
});

export const ScriptTabs = styled((props) => (
    <Tabs
      {...props}
      sx={{
        WebkitBoxShadow: `inset 0 -2px 0 ${COLORS.gray[70]}`,
        MozBoxShadow: `inset 0 -2px 0 ${COLORS.gray[70]}`,
        boxShadow: `inset 0 -2px 0 ${COLORS.gray[70]}`,
      }}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: COLORS.gray[70],
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: '30px',
      width: '100%',
      backgroundColor: COLORS.white,
    },
});


export const DefaultTab = styled((props) => <Tab disableRipple style={{minHeight:'40px'}} {...props} />)(
    ({ theme }) => (title1,{
        textTransform: 'none',
        color: COLORS.gray[60],
        padding: 0,
        minWidth: 0,
        marginLeft: "20px",
        marginRight: "26px",
        '&.Mui-selected': {
        color: COLORS.white
        },
        '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);
  

export const ScriptTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => (title1,{
        textTransform: 'none',
        color: COLORS.gray[60],
        padding: 0,
        minWidth: 0,
        marginLeft: "19px",
        marginRight: "19px",
        '&.Mui-selected': {
        color: COLORS.white
        },
        '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);
  
export const CustomSnackBar = (props) => {
    return <Snackbar
        autoHideDuration={1000}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={(event, reason) => {
            if(reason==='clickaway') {
                return;
            }
            props.setClose()
        }}
        open={props.open} sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <Alert icon={ props.icon ?? <CheckIcon sx={{color:localStorage.primaryColor, mr:'-9px'}}/> } sx={{mt:`${props.margin-24}px`,height:'36px', padding:'0 8px', fontFamily: 'Pretendard', fontWeight: 400, fontSize : '14px', background:COLORS.gray[100], color:COLORS.white, borderRadius:'2px'}} >
            {props.message}
        </Alert>
    </Snackbar>
};