import { createSlice } from "@reduxjs/toolkit";
import { saveProject } from "./creation/creaton";


const name = 'page';

// export const fetchPage = createAsyncThunk(
//     `${name}/navToPage`,
//     async (navPath : string) => {
//         try {

//         }
//     }
// )

const INTRODUCE = 'iamcnaistudio' // 스튜디오 소개
const INFORMATION = 'information' // 이용 안내
const TICKETS = 'iamcnaistudio?plan=true' // 요금제

const PROJECT = 'project'

export const PAGES = {
    INTRODUCE,
    INFORMATION,
    TICKETS,
    PROJECT
}

const initialState = {
    location: 'home',
    snackBar: {
        text: '',
        open: false,
        margin: 56,
        icon: null,
    }
}

export const pageSlice = createSlice({
    name,
    initialState,
    reducers: {
        setLocation: (
            state,
            action
        ) => {
            // console.log(window.location);
            // window.location.pathname =  `${action.payload}`
            state.location = action.payload;
        },
        setSnackBarOpen: (state, action) => {
            state.snackBar.open = true;
            state.snackBar.text = action.payload.text;
            state.snackBar.margin = action.payload.margin;
            state.snackBar.icon = action.payload?.icon ?? null;
        },
        setClose: (state,action) => {
            state.snackBar.open = false;
        }
    },
    extraReducers: {
        [saveProject.fulfilled.type]: (state,action) => {
            state.snackBar.open = true;
            state.snackBar.text = '저장되었습니다.';
            state.snackBar.margin = 56;
            state.snackBar.icon = null;
        }
    }
});

export const location = (state) => state.page.location;

export const snackBarOpen = (state) => state.page.snackBar.open;

export const snackBarText = (state) => state.page.snackBar.text;

export const snackBarMargin = (state) => state.page.snackBar.margin;

export const snackBarIcon = (state) => state.page.snackBar.icon;

export const {setLocation, setSnackBarOpen, setClose} = pageSlice.actions;

export default pageSlice.reducer;