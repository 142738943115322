import './App.css';
import Routes from './routes';
import {createTheme, ThemeProvider} from '@mui/material';
import store from './store';
import { Provider } from 'react-redux';
import { COLORS, DEFAULT_STANDARD } from './colors/color';
const theme = createTheme({
  typography: {
    fontFamily: "'Pretendard', sans-serif"
  },
  palette: {
    primary: {
      main: localStorage.primaryColor ? localStorage.primaryColor : DEFAULT_STANDARD.primary
    }
  }
})

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Routes/>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
