// 모든 정보를 토대로 자동적으로 내보내기 정보 폼으로 만드는 Slice 로 구상 중
// 각각의 정보가 수정 될 때 마다 extraReducer로 수정이 필요함 

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import html2canvas from "html2canvas";
import api from "../../lib/api";

/*
    전체 구조는
        {root} 현재 creation 파일
        [middle] [***]Info.js 파일
        (comp) 각각의 정보를 담당하는 js 파일
    root 와 comp 는 서로 몰라야 함 (연결 및 호출 금지)
*/


/*
    구상 한거를 하려면 일단 model, action, style 을 만들어 정보를 토대로 만든 후,
    해당 [middle] 에 정보들 수시로 바뀌도록 업데이트
    {root} 는 [middle] 이 업데이트 될 경우 해당 부분에 맞춰 action을 취하도록 구현
    또한 {root} 는 result 정보(project 정보)를 초기값으로 가지며 해당 부분을 수정 하면서 진행 
*/


const name = 'creation';

export const fetchImage = createAsyncThunk(
    `${name}/fetchImage`,
    async (req, thunkAPI) => {
        try{
            return (await api.uploadImage(req)).data
        } catch (e) {
            return thunkAPI.rejectWithValue(await e.response);
        }
    }
)

export const fetchImageByPdf = createAsyncThunk(
    `${name}/fetchImageByPdf`,
    async (req, thunkAPI) => {
        try{
            if (thunkAPI.getState().creation.root.pdfCancel){
                return thunkAPI.rejectWithValue({data : thunkAPI.getState().creation.root.pdfCurrentPjInfo, index : thunkAPI.getState().creation.slide.slideIdx});
            }
            return {data : (await api.uploadImage(req.data)).data, index: req.idx}
        } catch (e) {
            return thunkAPI.rejectWithValue(await e.response);
        }

    }
)

export const fetchCurrentPjInfo = createAsyncThunk(
    `${name}/fetchCurrentPjInfo` ,
    async (_, thunkAPI) => {
        try{
            return await thunkAPI.getState().creation.root.projectInfo;
        } catch (e) {
            return thunkAPI.rejectWithValue(await e);
        }
    }
)

export const fetchThumbnail = createAsyncThunk(
    `${name}/fetchThumbnail`,
    async (req, thunkAPI) => {
        try{
            return (await api.uploadImage(req)).data
        } catch (e) {
            return thunkAPI.rejectWithValue(await e.response);
        }
    }
)


export const fetchProject = createAsyncThunk(
    `${name}/fetchProject`,
    async (req, thunkAPI) => {
        try{        
            return (await api.loadProjectWithId({projectId: req.id})).data
        } catch (e) {
            return thunkAPI.rejectWithValue(await e.response);
        }
    }
)

export const fetchLipsync = createAsyncThunk(
    `${name}/fetchLipsync`, 
    async (req, thunkAPI) => {
        try{
            return (await api.lipsync(req)).data
        } catch (e) {
            return thunkAPI.rejectWithValue(await e.response);
        }
    }
)

export const saveProject = createAsyncThunk(
    `${name}/saveProject`, 
    async (req, thunkAPI) => {
        try{
            return (await api.saveProject(req)).data
        } catch (e) {
            return thunkAPI.rejectWithValue(await e.response);
        }
    }
)


const initialState = {
    projectInfo: null,
    original: {},
    sceneIdx: 0,
    focusId: null,
    projectId: -1,
    name: {
        project: '',
        folder: ''
    },
    render: false,
    thumbnail: null,
    thumbnailRef: null,
    loading: false,
    cursor: null,
    currentActId: null,
    pdfCancel: false,
    pdfCurrentPjInfo: null
};

export const creationSlice = createSlice({
    name,
    initialState,
    reducers: {
        changePdfCancel: (state, action) => {
            state.pdfCancel = action.payload;
        },
        changeOpposite: (state, action) => {
            switch(state.projectInfo.orientation){
                case 'landscape': {
                    state.projectInfo.orientation = 'portrait'
                    break;
                }
                case 'portrait': {
                    state.projectInfo.orientation = 'landscape'
                    break;
                }
            }
        },
        setRef: (state, action) => {
            state.thumbnail = action.payload;
        },
        setSlideIdx: (state, action) => {
            state.sceneIdx = action.payload.index;
            state.currentActId = null;
            state.cursor = 0;
        },
        addSlide: (state, action) => {
            state.projectInfo.scenes = [...state.projectInfo.scenes.slice(0, state.sceneIdx+1), action.payload.data, ...state.projectInfo.scenes.slice(state.sceneIdx+1)]
            state.sceneIdx+=1;
            state.currentActId = null;
            state.cursor = 0;
        },
        changeModel: (state, action) => {
            if (action.payload.imageSrc) {
                state.projectInfo.scenes[state.sceneIdx].ai_model_item.action.style.ai_model_id = action.payload.id;
                state.projectInfo.scenes[state.sceneIdx].ai_model_item.action.silhouette_img_path = action.payload.imageSrc;
                state.projectInfo.scenes[state.sceneIdx].ai_model_item.action.style.ai_model.model_name = action.payload.modelName;
                state.currentActId = null;
            }else{
                alert('준비 중입니다.')
            }

        },
        changeStyle: (state, action) => {
            if (state.projectInfo){

                if(action.payload.id === 28) {
                    state.projectInfo.scenes[state.sceneIdx].ai_model_item.x_shift = 80;
                    state.projectInfo.scenes[state.sceneIdx].ai_model_item.y_shift = 45;
                    state.projectInfo.scenes[state.sceneIdx].ai_model_item.scale = 960/132.75*100;
                }

                if (state.projectInfo.scenes[state.sceneIdx].ai_model_item.action.style.id === 28) {
                    state.projectInfo.scenes[state.sceneIdx].ai_model_item.x_shift = 80;
                    state.projectInfo.scenes[state.sceneIdx].ai_model_item.y_shift = 45;
                    state.projectInfo.scenes[state.sceneIdx].ai_model_item.scale = 100;
                }

                state.projectInfo.scenes[state.sceneIdx].ai_model_item.action.style.id = action.payload.id;
                state.projectInfo.scenes[state.sceneIdx].ai_model_item.action.silhouette_img_path = action.payload.imageSrc;
                state.currentActId = null;
            }
        },
        changeAction: (state, action) => {
            if (state.projectInfo){
                state.projectInfo.scenes[state.sceneIdx].ai_model_item.action_id = action.payload.id;
                state.projectInfo.scenes[state.sceneIdx].ai_model_item.action.silhouette_img_path = action.payload.imageSrc;                
            }
        },
        changeBackground: (state, action) => {
            state.projectInfo.scenes[state.sceneIdx].background_path = action.payload;
        },
        changeBgResize: (state, action) => {
            state.projectInfo.scenes[state.sceneIdx].bg_resize_option = action.payload;
        },
        changeCaption: (state, action) => {
            state.projectInfo.scenes[state.sceneIdx].subtitle_item.is_rendering = action.payload;
        },
        deleteImageContent: (state, action) => {
            const removeIdx = state.projectInfo.scenes[state.sceneIdx].image_items.findIndex((item) => item.id === action.payload);
            state.projectInfo.scenes[state.sceneIdx].image_items.splice(removeIdx,1);
        },
        getBackgroundFromPdf: (state, action) => {

        },
        changeFocus: (state, action) => {
            state.focusId = action.payload;
        },
        reset: (state, action) => {
            Object.assign(state, initialState);
        },
        changePos: (state, action) => {
            switch(action.payload.type) {
                case 'model' : {
                    state.projectInfo.scenes[state.sceneIdx].ai_model_item.x_shift = action.payload.data.x;
                    state.projectInfo.scenes[state.sceneIdx].ai_model_item.y_shift = action.payload.data.y;
                    break;
                }
                case 'image' : {
                    const changeIdx = state.projectInfo.scenes[state.sceneIdx].image_items.findIndex((item) => {if(item) return item.id === action.payload.data.id})

                    console.log(state.projectInfo.scenes[state.sceneIdx].image_items[changeIdx]);
                    state.projectInfo.scenes[state.sceneIdx].image_items[changeIdx].x_shift = action.payload.data.x;
                    state.projectInfo.scenes[state.sceneIdx].image_items[changeIdx].y_shift = action.payload.data.y;
                    break;
                }
                case 'text':{
                    const changeIdx = state.projectInfo.scenes[state.sceneIdx].text_items.findIndex((item) => item.id === action.payload.data.id);
                    state.projectInfo.scenes[state.sceneIdx].text_items[changeIdx].x_shift = action.payload.data.x;
                    state.projectInfo.scenes[state.sceneIdx].text_items[changeIdx].y_shift = action.payload.data.y;
                    break;
                }
            }
        },
        changeSize: (state, action) => {
            switch(action.payload.type) {
                case 'model': {
                    state.projectInfo.scenes[state.sceneIdx].ai_model_item.scale = action.payload.data.scale;
                    break;
                }
                case 'image': {
                    const changeIdx = state.projectInfo.scenes[state.sceneIdx].image_items.findIndex((item) => item.id === action.payload.data.id)
                    state.projectInfo.scenes[state.sceneIdx].image_items[changeIdx].scale = action.payload.data.scale;
                    break;
                }
                case 'text': {

                }
            }
        },
        changeLang: (state, action) => {
            state.projectInfo.scenes[state.sceneIdx].lang = action.payload;
        },
        changeScript: (state, action) => {
            state.projectInfo.scenes[state.sceneIdx].script = action.payload.replaceAll('<br>','');
        },
        resetAction: (state, action) => {
            const removeAction = (script, result='') => {
                const actStart = script.indexOf('(ACTION-START)');
                const actEnd = script.indexOf('(ACTION-END)');
                if (actStart!==-1 && actEnd !== -1){
                    result += script.substring(0,actStart);
                    const actionInfo = script.substring(actStart,actEnd);
                    const actionScript = actionInfo.split(':')[1];
                    result += actionScript;
                    return removeAction(script.substring(actEnd+'(ACTION-END)'.length), result)
                }
                result += script;
                return result;
            }
            state.projectInfo.scenes[state.sceneIdx].script = removeAction(state.projectInfo.scenes[state.sceneIdx].script)
        },
        changeCursor: (state, action) => {
            state.cursor = action.payload;
        },
        changeFocusAction: (state, action) => {
            state.currentActId = action.payload;
        },
        copyScene: (state, action) => { 
            const scene = JSON.parse(JSON.stringify(state.projectInfo.scenes[state.sceneIdx]));
            state.projectInfo.scenes = [...state.projectInfo.scenes.slice(0, state.sceneIdx), scene, ...state.projectInfo.scenes.slice(state.sceneIdx)]
            state.sceneIdx+=1;
            state.currentActId = null;
            state.cursor = 0;
        },
        deleteScene: (state, action) => {
            state.projectInfo.scenes = [...state.projectInfo.scenes.slice(0, state.sceneIdx), ...state.projectInfo.scenes.slice(state.sceneIdx+1)]
            state.sceneIdx = action.payload.index;
            state.currentActId = null;
            state.cursor = 0;
            state.render = true;
        },
        resetScene: (state, action) => {
            const item = state.original.scenes.find((item) => item.id === state.projectInfo.scenes[state.sceneIdx].id)
            if (item){
                const scene = JSON.parse(JSON.stringify(item));
                state.projectInfo.scenes[state.sceneIdx] = scene;
            }
            state.currentActId = null;
            state.cursor = 0;
            state.render = true;
        },
        renderOff: (state, action) => {
            state.render = false;
        },
        addSceneByPdf: (state, action) => {

            let index = action.payload.count - ((state.projectInfo.scenes.length-1) - state.sceneIdx);
            if (index<0) index = 0;

            let arr = new Array(index).fill(undefined).map((val,idx) => idx);
            let array = [];
            arr.map((item, index) => {
                array.push(action.payload.data)
            })

            state.projectInfo.scenes = state.projectInfo.scenes.concat(array)
        },
        cancelAddPdf: (state, action) => {
            state.projectInfo.scenes = action.payload;
        },
        addText: (state, action) => {
            state.projectInfo.scenes[state.sceneIdx].text_items.push({
                id:`newT${state.projectInfo.scenes[state.sceneIdx].text_items.length}`,
                text: action.payload.text,
                font_name: 'NotoSansKR-Regular.otf',
                size:100,
                text_color: '#ffffff',
                type: 'text',
                x_shift: 0,
                y_shift: 0,
                z_index: state.projectInfo.scenes[state.sceneIdx].text_items.length+state.projectInfo.scenes[state.sceneIdx].image_items.length+1
            })

            state.projectInfo.scenes.map((item,index) => {
                state.projectInfo.scenes[index].ai_model_item.z_index = item.text_items.length+item.image_items.length+1;
            })
        },
        deleteText: (state, action) => {
            const removeIdx = state.projectInfo.scenes[state.sceneIdx].text_items.find((item) => item.id === action.payload);
            state.projectInfo.scenes[state.sceneIdx].text_items.splice(removeIdx,1);
        },
        changeText: (state, action) => {
            state.projectInfo.scenes[state.sceneIdx].text_items[-(state.focusId+1)].text = action.payload.text;
        },
        changeTextDetail: (state, action) => {
            switch(action.payload.type) {
                case 'font': {
                    state.projectInfo.scenes[state.sceneIdx].text_items[-(state.focusId+1)].font_name = action.payload.data;
                    break;
                }
                case 'color': {
                    state.projectInfo.scenes[state.sceneIdx].text_items[-(state.focusId+1)].text_color = action.payload.data;
                    break;
                }
                case 'size': {
                    state.projectInfo.scenes[state.sceneIdx].text_items[-(state.focusId+1)].size = action.payload.data;
                    break;
                }
            }
        },
        setSmartWords:(state, action) => {
            state.projectInfo.smart_words = action.payload;
        },
        setProjectName:(state, action) => {
            state.projectInfo.project_name = action.payload;
            state.name.project = action.payload;
        },
        setFolder:(state, action) => {
            state.projectInfo.folder_id = action.payload.id;
            state.name.folder = action.payload.name;
        }
     }, 
    extraReducers: {
        [saveProject.rejected.type] : (state, action) => {
            alert('프로젝트 저장에 실패하였습니다.')
        },
        [saveProject.fulfilled.type] : (state, action) => {
        },
        [fetchProject.pending.type] : (state, action) => {},
        [fetchProject.fulfilled.type] : (state, action) => {
            state.original = action.payload.project;
            state.projectInfo = state.original;
            state.projectId = state.original.id;
            state.name.project = state.original.project_name;
            state.name.folder = state.original?.folder?.folder_name ?? '없음';
            state.sceneIdx = 0;
            state.render = true;
        },
        [fetchProject.rejected.type] : (state, action) => {},
        [fetchImage.fulfilled.type] : (state, action) => {
            let value = action.payload.upload_images;

            switch(value[value.length-1].type){
                case 'background': {
                    state.projectInfo.scenes[state.sceneIdx].background_path = value[value.length-1].file_path;
                    break;
                }
                case 'imageItem': {
                    state.projectInfo.scenes[state.sceneIdx].image_items.push({
                        id: `new${state.projectInfo.scenes[state.sceneIdx].image_items.length}`,
                        file_path: value[value.length-1].file_path,
                        scale: 100,
                        type: "image",
                        x_shift: 0,
                        y_shift: 0,
                        z_index: state.projectInfo.scenes[state.sceneIdx].text_items.length+state.projectInfo.scenes[state.sceneIdx].image_items.length+1
                    })

                    state.projectInfo.scenes.map((item,index) => {
                        state.projectInfo.scenes[index].ai_model_item.z_index = item.text_items.length+item.image_items.length+1;
                    })

                    break;
                }
            }
        },
        [fetchImageByPdf.fulfilled.type]: (state, action) => {
            let value = action.payload.data.upload_images;

            try {
                state.projectInfo.scenes[state.sceneIdx+action.payload.index]?.image_items.push({
                    id: `new${state.projectInfo.scenes[state.sceneIdx+action.payload.index].image_items.length}`,
                    file_path: value[value.length-1].file_path,
                    scale: 100,
                    type: "image",
                    x_shift: 0,
                    y_shift: 0,
                    z_index: state.projectInfo.scenes[state.sceneIdx+action.payload.index].image_items.length+state.projectInfo.scenes[state.sceneIdx+action.payload.index].text_items.length+1
                })
    
                state.projectInfo.scenes.map((item,index) => {
                    state.projectInfo.scenes[index].ai_model_item.z_index = item.text_items.length+item.image_items.length+1;
                })
    
            }catch (e) {

            }

        },
        [fetchImageByPdf.rejected.type]: (state, action) => {
            if(state.pdfCurrentPjInfo !== null) {
                state.projectInfo =  state.pdfCurrentPjInfo;
                state.pdfCurrentPjInfo = null;
                state.render = true;
                state.pdfCancel = false;
            }
        },
        [fetchLipsync.fulfilled.type] : (state, action) => {
            // window.location.href = action.payload.project.output_path;
            const handleDownload = (outputPath) => {
                fetch(outputPath, { method: 'GET' })
                    .then(res => {
                        return res.blob();
                    })
                    .then(blob => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = outputPath.split('/')[outputPath.split('/').length-1];
                        document.body.appendChild(a);
                        a.click();
                        setTimeout(_ => {
                            window.URL.revokeObjectURL(url);
                        }, 60000);
                        a.remove();
                        var link = document.location.href;
                        if(link.includes("/history")){
                            window.location.reload()
                        }
                    })
                    .catch(err => {
                        console.error('err: ', err);
                    });
            };
            handleDownload(action.payload.project.output_path)
            
        },
        [fetchLipsync.rejected.type] : (state, action) => {
            if(action.payload.data.message=='Request failed with status code 500'){
                alert("현재 사용할 수 없습니다.")
            }else{
                alert(action.payload.data.message)
            }
            var link = document.location.href;
            if(link.includes("/history")){
                window.location.reload()
            }
        },
        [fetchThumbnail.fulfilled.type]: (state, action) => {
            state.loading = false;
        },
        [fetchCurrentPjInfo.fulfilled.type]: (state, action) => {
            state.pdfCurrentPjInfo = {...action.payload};
        }
    }
})

const {setSlideIdx, addSlide} = creationSlice.actions;

export const slideActions = {setSlideIdx, addSlide};

const {changeModel, changeAction, changeStyle,} = creationSlice.actions;

export const modelActions = {changeModel, changeAction, changeStyle};

const {changeFocus, changePos, changeSize} = creationSlice.actions;

export const changeActions = {changeFocus, changePos, changeSize};

const {changeBackground, getBackgroundFromPdf, changeBgResize} = creationSlice.actions;

export const backgroundActions = {changeBackground, getBackgroundFromPdf, changeBgResize};

export const {changeLang, changeScript, changeCaption, resetAction, changeCursor, changeFocusAction} = creationSlice.actions;

const {copyScene, deleteScene, resetScene, renderOff, setRef} = creationSlice.actions;

export const sceneActions = {copyScene, deleteScene, resetScene, renderOff, setRef}; 

const {deleteImageContent, addSceneByPdf, addText, deleteText, changeText, changeTextDetail} = creationSlice.actions;

export const contentActions = {deleteImageContent, addSceneByPdf, addText, deleteText, changeText, changeTextDetail};

export const {cancelAddPdf, changePdfCancel} = creationSlice.actions;

export const {setSmartWords} = creationSlice.actions;

export const currentActId = (state) => state.creation.root.currentActId;

export const project = (state) => state.creation.root.projectInfo;

export const projectId = (state) => state.creation.root.projectId; 

export const projectName = (state) => state.creation.root.name.project;

export const folderName = (state) => state.creation.root.name.folder;

export const renderFlag = (state) => state.creation.root.render;

export const thumbNail = (state) => state.creation.root.thumbnail;

export const thumbnailRef = (state) => state.creation.root.thumbnailRef;

export const focusId = (state) => state.creation.root.focusId;

export const smartWords = (state) => state.creation.root.projectInfo?.smart_words;

export const orientation = (state) => state.creation.root.projectInfo?.orientation ?? 'landscape';

export const loading = (state) => state.creation.root.loading;

export const cursor = (state) => state.creation.root.cursor;

export const {changeOpposite, setProjectName, setFolder} = creationSlice.actions;

export const {reset} = creationSlice.actions;

export default creationSlice.reducer;