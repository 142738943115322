import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../lib/api";

const name = 'projects'

export const fetchFolders = createAsyncThunk(
    `${name}/fetchFolders`,
    async (req, thunkAPI ) => {
        try {
            return (await api.foldersByUserId({})).data;
        } catch (e) {
            return thunkAPI.rejectWithValue(await e.response.data);
        }
    }
)

export const fetchProjects = createAsyncThunk(
    `${name}/fetchProjects`,
    async (req, thunkAPI ) => {
        try {
            return (await api.projects({page: req.page, searchText: req.searchText, folderId: req.folderId})).data;
        } catch (e) {
            return thunkAPI.rejectWithValue(await e.response.data);
        }
    }
)

export const fetchHistory = createAsyncThunk(
    `${name}/fetchHistory`,
    async (req, thunkAPI ) => {
        try {
            return (await api.history({page: req.page, searchText: req.searchText})).data;
        } catch (e) {
            return thunkAPI.rejectWithValue(await e.response.data);
        }
    }
)

export const changeFolderName = createAsyncThunk(
    `${name}/changeFolderName`,
    async (req, thunkAPI ) => {
        try {
            return (await api.changeFolderName({folderId: req.folderId, folderName: req.folderName})).data;
        } catch (e) {
            return thunkAPI.rejectWithValue(await e.response.data);
        }
    }
)

export const deleteFolder = createAsyncThunk(
    `${name}/deleteFolder`,
    async (req, thunkAPI ) => {
        try {
            return (await api.deleteFolder({folderId: req.folderId})).data;
        } catch (e) {
            return thunkAPI.rejectWithValue(await e.response.data);
        }
    }
)

export const makeNewFolder = createAsyncThunk(
    `${name}/makeNewFolder`,
    async (req, thunkAPI ) => {
        try {
            return (await api.makeNewFolder()).data;
        } catch (e) {
            return thunkAPI.rejectWithValue(await e.response.data);
        }
    }
)

export const fetchSummaryData = createAsyncThunk(
    `${name}/fetchSummaryData`,
    async (req, thunkAPI ) => {
        try {
            return (await api.summaryData()).data;
        } catch (e) {
            return thunkAPI.rejectWithValue(await e.response.data);
        }
    }
)

const initialState = {
    folders: [],
    currentProjects: [],
    history: [],
    summaryData:{templates:[], projects:[], histories:[]},
    lastPage: 1
}

export const projectsSlice = createSlice({
    name,
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchFolders.pending.type]: (state, action) => {},
        [fetchFolders.fulfilled.type]: (state, action) => {
            state.folders = action.payload.folders;
            console.log(state.folders)
        },
        [fetchFolders.rejected.type]: (state, action) => {
            state.folders = [];
        },


        [fetchProjects.pending.type]: (state, action) => {},
        [fetchProjects.fulfilled.type]: (state, action) => {
            state.currentProjects = action.payload.data.items;
            state.lastPage = action.payload.data.lastPage;
        },
        [fetchProjects.rejected.type]: (state, action) => {
            state.currentProjects = [];
            state.lastPage = 1;
        },


        [fetchHistory.pending.type]: (state, action) => {},
        [fetchHistory.fulfilled.type]: (state, action) => {
            state.history = action.payload.data.items;
            state.lastPage = action.payload.data.lastPage;
        },
        [fetchHistory.rejected.type]: (state, action) => {
            state.history = [];
            state.lastPage = 1;
        },

        [changeFolderName.pending.type]: (state, action) => {},
        [changeFolderName.fulfilled.type]: (state, action) => {
            const index = state.folders.findIndex((item) => item.id === action.payload.folder.id);
            state.folders[index].folder_name = action.payload.folder.folder_name;
        },
        [changeFolderName.rejected.type]: (state, action) => {
            
        },


        [deleteFolder.pending.type]: (state, action) => {},
        [deleteFolder.fulfilled.type]: (state, action) => {
            const index = state.folders.findIndex((item) => item.id === action.payload.folder.id);
            state.folders.splice(index, 1)
        },
        [deleteFolder.rejected.type]: (state, action) => {
            
        },


        [makeNewFolder.pending.type]: (state, action) => {},
        [makeNewFolder.fulfilled.type]: (state, action) => {
            state.folders.push(action.payload.folder)
        },
        [makeNewFolder.rejected.type]: (state, action) => {
            
        },


        [fetchSummaryData.pending.type]: (state, action) => {},
        [fetchSummaryData.fulfilled.type]: (state, action) => {
            state.summaryData.templates = action.payload.templates
            state.summaryData.projects = action.payload.projects
            state.summaryData.histories = action.payload.histories
            console.log(action.payload)
        },
        [fetchSummaryData.rejected.type]: (state, action) => {
            
        },


        
    }
})

export const folders = (state) => state.projects.folders;

export default projectsSlice.reducer;