export const COLORS = {
    black: '#000000',
    gray : {
        100 : '#0F0F0F',
        90 : '#1C1B1E',
        80 : '#242226',
        70 : '#313033',
        60 : '#605D62',
        50 : '#939094',
        40 : '#C9C5C9',
        30 : 'rgba(201, 197, 201, 0.5)',
        20 : '#EAE9EB',
        15 : 'linear-gradient(0deg, rgba(204, 204, 204, 0.2), rgba(204, 204, 204, 0.2)), #FCFAFC',
        10 : '#FCFAFC',
    },
    white : '#FFFFFF',
    red: '#FF5B5A',
    green: '#32D74B'
}

export const DEFAULT_STANDARD = {
    primary: '#7A59FD',
    secondary: '#E7DEFF'
}
