// 현재 Scene에서 수정 되는 Model에 관한 정보를 가지고 있는 Slice
// 현재 구상은 다른 Slice 에 select action을 두고 여기서 그 친구를 불러 오는 방향?

import { createSlice } from "@reduxjs/toolkit";
import { changeActions, changeTempModel, fetchProject, modelActions, reset, sceneActions, slideActions } from "../creaton";



// 현재 들어갈 정보 중 View 에서 보이는 정보는 선택된 모델, 선택된 스타일, 선택된 동작

// 이외는 좌표 값 (x,y), scale 값 <- default 정보를 바탕으로 초기화 및 저장된 정보를 초기값으로 초기화

const name = 'modelInfo';



const initialState = {
    coordinates : {
        x: 80,
        y: 45,
    },
    scale : 100,
    modelId: 1,
    styleId: 1,
    actionId: 1,
    path: '',
    modelName: '',
    original: null,
    imgLoading: false,
    modelChange: false,
    modelLoading: true,
}


export const projectModelInfo = createSlice({
    name,
    initialState,
    reducers: {
        loadDone: (state, action) => {
            state.imgLoading = false;
        },
        changeTTSFlag: (state, action) => {
            state.modelChange = false;
        },
        modelLoaded: (state, action) => {
            state.modelLoading = false;
        }
    },
    extraReducers: {
        [fetchProject.fulfilled.type] : (state, action) => {
            state.original = action.payload.project;
            state.path = action.payload.project.scenes[0].ai_model_item.action.silhouette_img_path;
            state.coordinates = {x: action.payload.project.scenes[0].ai_model_item.x_shift, y: action.payload.project.scenes[0].ai_model_item.y_shift};
            state.actionId = action.payload.project.scenes[0].ai_model_item.action_id;
            state.styleId = action.payload.project.scenes[0].ai_model_item.action.style.id;
            state.modelId = action.payload.project.scenes[0].ai_model_item.action.style.ai_model_id;
            state.scale = action.payload.project.scenes[0].ai_model_item.scale;
            state.modelName = action.payload.project.scenes[0].ai_model_item.action.style.ai_model.model_name;
        },
        [modelActions.changeModel] : (state, action) => {
            if(!action.payload.imageSrc) return

            if (state.original){
                state.modelName = action.payload.modelName;
                state.modelId = action.payload.id;
                state.path = action.payload.imageSrc;
                state.modelChange = true;
                state.modelLoading = true;
            }
        },
        [modelActions.changeStyle] : (state, action) => {
            if (state.original){
                if(action.payload.id === 28) {
                    state.scale = 960/132.75*100;
                    state.coordinates = {x: 80, y: 45};
                    state.imgLoading = true;
                }

                if (state.styleId === 28) {
                    state.coordinates = {x: 80, y: 45};
                    state.scale = 100;
                    state.imgLoading = true;
                }
                state.modelLoading = true;
                state.styleId = action.payload.id;
                state.path = action.payload.imageSrc;
            }
        },
        [modelActions.changeAction] : (state, action) => {
            if (state.original){
                state.actionId = action.payload.id;
                state.path = action.payload.imageSrc;
            }
        },
        [slideActions.setSlideIdx]: (state, action) => {
            state.path = action.payload.data.ai_model_item.action.silhouette_img_path;
            state.coordinates = {x: action.payload.data.ai_model_item.x_shift, y: action.payload.data.ai_model_item.y_shift};
            state.actionId = action.payload.data.ai_model_item.action_id;
            state.styleId = action.payload.data.ai_model_item.action.style.id;
            state.modelId = action.payload.data.ai_model_item.action.style.ai_model_id;
            state.scale = action.payload.data.ai_model_item.scale;
            state.modelName = action.payload.data.ai_model_item.action.style.ai_model.model_name;
            state.modelLoading = true;
        },
        [slideActions.addSlide]: (state, action) => {
            state.path = action.payload.data.ai_model_item.action.silhouette_img_path;
            state.coordinates = {x: action.payload.data.ai_model_item.x_shift, y: action.payload.data.ai_model_item.y_shift};
            state.actionId = action.payload.data.ai_model_item.action_id;
            state.styleId = action.payload.data.ai_model_item.action.style.id;
            state.modelId = action.payload.data.ai_model_item.action.style.ai_model_id;
            state.scale = action.payload.data.ai_model_item.scale;
            state.modelName = action.payload.data.ai_model_item.action.style.ai_model.model_name;
            state.modelLoading = true;
        },
        [sceneActions.deleteScene]: (state, action) => {
            state.path = action.payload.data.ai_model_item.action.silhouette_img_path;
            state.coordinates = {x: action.payload.data.ai_model_item.x_shift, y: action.payload.data.ai_model_item.y_shift};
            state.actionId = action.payload.data.ai_model_item.action_id;
            state.styleId = action.payload.data.ai_model_item.action.style.id;
            state.modelId = action.payload.data.ai_model_item.action.style.ai_model_id;
            state.scale = action.payload.data.ai_model_item.scale;
            state.modelName = action.payload.data.ai_model_item.action.style.ai_model.model_name;
            state.modelLoading = true;
        },
        [reset]: (state, action) => {
            Object.assign(state, initialState);
        },
        [sceneActions.resetScene]: (state, action) => {

            const originalItem = state.original.scenes.find((item) => item.id === action.payload.id);
            if(originalItem !== undefined) {
                const scene = JSON.parse(JSON.stringify(originalItem));
            


                state.path = scene.ai_model_item.action.silhouette_img_path;
                state.coordinates = {x: scene.ai_model_item.x_shift, y: scene.ai_model_item.y_shift};
                state.actionId = scene.ai_model_item.action_id;
                state.styleId = scene.ai_model_item.action.style.id;
                state.modelId = scene.ai_model_item.action.style.ai_model_id;
                state.scale = scene.ai_model_item.scale;
                state.modelName = scene.ai_model_item.action.style.ai_model.model_name;
    
                state.imgLoading = true;
                state.modelChange = true;
                state.modelLoading = true;
            } 

        },
        [changeActions.changePos]: (state, action) => {
            if (action.payload.type === 'model'){
                state.coordinates = {x: action.payload.data.x, y: action.payload.data.y};
            }
        },
        [changeActions.changeSize]: (state, action) => {
            if (action.payload.type === 'model'){
                state.scale = action.payload.data.scale;
            }
        }
    }
})

export const modelState = (state) => state.creation.modelP

export const loading = (state) => state.creation.modelP.imgLoading;

export const modelChange = (state) => state.creation.modelP.modelChange;

export const skeletonDisplay = (state) => state.creation.modelP.modelLoading;

export const {loadDone, changeTTSFlag, modelLoaded} = projectModelInfo.actions;

export default projectModelInfo.reducer;
