import { lazy } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { CustomSnackBar } from "../components/default/DefaultComponent";
import { setClose, snackBarIcon, snackBarIsError, snackBarMargin, snackBarOpen, snackBarText } from "../slice/page";

const Summary = lazy(() => import('../pages/summary'))
const Project = lazy(() => import('../pages/project'))
const Projects = lazy(() => import('../pages/projects'))
const History = lazy(() => import('../pages/history'))
const Smartwords = lazy(() => import('../pages/smartwords'))
const Login = lazy(() => import('../pages/login'))
const Password = lazy(() => import('../pages/password'))
const AdminThemeSetting = lazy(() => import('../pages/admin'))
const Landing = lazy(() => import('../pages/landing'))

class routeItem {
    constructor(path,key,exact,component,routes){
        this.path = path;
        this.key = key;
        this.exact = exact;
        this.component = component;
        this.routes = routes;
    }
}

class routes {
    constructor(){
        this.routes = [
            new routeItem("/summary", "SUMMARY", true, Summary,[]),
            new routeItem("/project/:projectId", "PROJECT", true, Project,[]),
            new routeItem("/projects", "PROJECTS", true, Projects,[]),
            new routeItem("/history", "HISTORY", true, History,[]),
            new routeItem("/mysmartword", "SMARTWORDS", true, Smartwords,[]),
            new routeItem("/login", "LOGIN", true, Login,[]),
            new routeItem("/settings/password", "PASSWORD", true, Password,[]),
            new routeItem("/settings/admin", "ADMIN", true, AdminThemeSetting,[]),
            new routeItem("/iamcnaistudio", "LANDING", true, Landing,[])
        ]
    }

    get allRoutes(){
        return this.routes
    }
}

const ROUTES = new routes()

export default ROUTES


export function RenderRoutes({ routes }) {

    const open = useSelector(snackBarOpen);
    const text = useSelector(snackBarText);
    const margin = useSelector(snackBarMargin);
    const icon = useSelector(snackBarIcon);
    const dispatch = useDispatch();

    return (
        <BrowserRouter>
            <CustomSnackBar open={open} setClose={() => {dispatch(setClose())}} margin={margin} message={text} icon={icon} />
            <Routes>
            {routes.map((route, i) => {
                return ((route.key === "LANDING") || (route.key === "LOGIN"))
                        ?  <Route key={route.key} path={route.path} exact={route.exact} element={ <route.component /> }/>
                        :  <Route key={route.key} path={route.path} exact={route.exact} element={ <PrivateRoute component={<route.component />}/> } />
            })}
            <Route exact path="/" element={ localStorage.getItem('accessToken') ? <Navigate to="/summary" /> : <Navigate to="/iamcnaistudio" /> } />  
            <Route component={() => <h1>Not Found!</h1>} />
            </Routes>
        </BrowserRouter>
    );
}

function PrivateRoute({ component: Component }) {
    return (
      localStorage.getItem('accessToken') ? Component : <Navigate to='/login' />
    )
  }